import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeOptions, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { PropsWithChildren, useMemo } from 'react';
import { themeOptions } from '../theme/theme';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { faIR } from '@mui/x-date-pickers/locales';
import { faIR as coreFaIR } from '@mui/material/locale';

interface IProps {
  theme?(themeOptions: ThemeOptions): Partial<ThemeOptions>;
}

const MaterialProvider = ({ children, theme }: PropsWithChildren<IProps>) => {
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const innerTheme = theme?.(themeOptions);
  const usedTheme = useMemo(
    () => createTheme(innerTheme ?? themeOptions, faIR, coreFaIR),
    [innerTheme]
  );

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={usedTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default MaterialProvider;
