import { Grid, Box } from '@mui/material';
import { useAppSelector, useGetProductsQuery } from '../../redux';
import { ProductCard } from './components/ProductCard';
import React, { useEffect, useState } from 'react';
import { useLoading } from '../../components/loading';
import { ProductCardSkeleton } from './components/ProductCardSkeleton';
import { ProColumn } from './components/ProductCard.styles';
import { CPagination } from '../../components/ui-components/CPagination/CPagination';
import { BannerSlider } from './components/BannerSlider';
import { MessageSlider } from './components/MessageSlider';
import EnamadBox from '../../components/footers/EnamadBox';
import { BannersContainer } from './components/BannersContainer';

export interface IShowBanners {
  kala2: boolean;
  unlimited: boolean;
  special: boolean;
}
const Product = () => {
  const { setIsLoading } = useLoading();
  const PERPAGE: number = 18;
  const [page, setPage] = useState(1);
  const [showAdBanners, setShowAddBanners] = useState<IShowBanners>({
    kala2: false,
    unlimited: false,
    special: false,
  });
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const { data: products, isLoading } = useGetProductsQuery({
    pagination: {
      page: page,
    },
    per_page: PERPAGE,
  });
  useEffect(() => {
    if (isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoading]);

  const categories = useAppSelector((state) => state.categories.data);
  const checkCategories = () => {
    if (typeof categories !== 'undefined' && categories?.data?.length > 0) {
      categories?.data?.map((cat) => {
        if (cat.name === 'فروش موردی') {
          let newShowBanners = showAdBanners;
          const findKala2 = cat?.sub_categories?.findIndex(
            (subCat) => Number(subCat.id) === categories?.meta?.tags_id?.kala2
          );
          const findUnlimited = cat?.sub_categories?.findIndex(
            (subCat) => Number(subCat.id) === categories?.meta?.tags_id?.kala_plus
          );
          const findSpecial = cat?.sub_categories?.findIndex(
            (subCat) => Number(subCat.id) === categories?.meta?.tags_id?.frosh_vizheh
          );
          if (findKala2 > -1) {
            newShowBanners = { ...newShowBanners, kala2: true };
          }
          if (findUnlimited > -1) {
            newShowBanners = { ...newShowBanners, unlimited: true };
          }
          if (findSpecial > -1) {
            newShowBanners = { ...newShowBanners, special: true };
          }
          setShowAddBanners(newShowBanners);
        }
      });
    }
  };
  useEffect(() => {
    checkCategories();
  }, [categories]);
  return (
    <>
      <Grid container spacing={0}>
        <BannerSlider />
      </Grid>
      <Grid container spacing={0}>
        <MessageSlider />
      </Grid>
      <BannersContainer showAdBanners={showAdBanners} />
      <Box px={2}>
        <Grid container spacing={3}>
          {products?.data?.map?.((item, i) => {
            return (
              <ProColumn key={i} item xs={6} sm={4} md={3} lg={2}>
                <ProductCard item={item} />
              </ProColumn>
            );
          }) ??
            [...Array(18)].map((i) => {
              //TODO - remove in future
              return (
                <ProColumn key={i} item xs={6} sm={4} md={3} lg={2}>
                  <ProductCardSkeleton />
                </ProColumn>
              );
            })}
        </Grid>
      </Box>

      {products?.meta?.last_page !== 1 ? (
        <Box
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          pt={5}
          pb={10}
          sx={{
            width: '100%',
          }}
        >
          <CPagination meta={products?.meta} handlechange={handleChange} />
        </Box>
      ) : null}

      {/* Todo:recommend below box after adding enamad  */}
      <Box
        my={5}
        justifyContent="center"
        alignItems="center"
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <EnamadBox />
      </Box>
    </>
  );
};
export default Product;
