import { styled } from '@mui/material';
import { MenuItem as BaseMenuItem } from '@mui/base/MenuItem';
import { Box, Grid } from '@mui/material';

export const CategoryItems = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[800],
  position: 'relative',
  '.category-trigger': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: theme.typography.body1,
    padding: theme.spacing(0, 5),
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  '.link': {
    display: 'flex',
    alignItems: 'center',
    svg: {
      fontSize: '0.9rem',
    },
    '&:hover': {
      'p,svg': {
        color: theme.palette.primary.dark,
      },
    },
  },
  p: {
    cursor: 'pointer',
  },
}));
export const DropDownBox = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.fab,
  position: 'absolute',
  top: 50,
  right: 0,
  left: 0,
}));
export const DropDownContainer = styled(Grid)(({ theme }) => ({
  width: 700,
  [theme.breakpoints.down(1030)]: {
    width: 600,
  },
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[15],
  borderRadius: theme.spacing(1),
}));
