import {
  IAddRemoveShopCartRequest,
  IProduct,
  addShop,
  useAddShopCartMutation,
  useAppDispatch,
  useAppSelector,
  deleteShop,
  useRemoveShopCartMutation,
} from '../../../redux';
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { StyledLineThroughTypography, StyledProductCard, TopImgIcon } from './ProductCard.styles';
import { thousandSeparator } from '../../../utils/price';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { CButton } from '../../../components/ui-components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { toFarsiNumber } from '../../../utils/helper';
import infinityIcon from '../../../assets/img/INFINITY.png';
import kala2Icon from '../../../assets/img/KALA2.png';
import specialIcon from '../../../assets/img/SPECIAL.png';

interface IProductCard {
  item: IProduct;
}

export const ProductCard: React.FC<IProductCard> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [count, setCount] = useState<number>(0);
  const [shopId, setShopId] = useState<number>(0);
  const [addShopCart] = useAddShopCartMutation({});
  const [removeShopCart] = useRemoveShopCartMutation({});
  const [addShopLoading, setAddShopLoading] = useState<boolean>(false);
  const shopCart = useAppSelector((state) => state.shopCart.data);
  const categories = useAppSelector((state) => state.categories.data);
  const onAddCart = (data: IAddRemoveShopCartRequest, item: IProduct) => {
    setAddShopLoading(true);
    addShopCart({
      ...data,
    })
      .unwrap()
      .then((res) => {
        dispatch(addShop({ ...data, item, cart_id: res?.data?.id })); //Todo:add ProductObj
        setAddShopLoading(false);
      })
      .catch((err) => {
        setAddShopLoading(false);
      });
  };
  const onDeleteCart = (data: IAddRemoveShopCartRequest) => {
    setAddShopLoading(true);
    removeShopCart({
      ...data,
    })
      .unwrap()
      .then((res) => {
        setAddShopLoading(false);
        dispatch(deleteShop(data));
      })
      .catch((err) => {
        setAddShopLoading(false);
      });
  };

  useEffect(() => {
    let countOfProduct = 0;
    let ShopCartId = 0;
    if (typeof shopCart !== 'undefined' && shopCart?.length > 0) {
      let findItem = shopCart.find((x) => Number(x.product_id) === Number(item?.id));
      countOfProduct = findItem?.quantity ?? 0;
      ShopCartId = findItem?.id ?? 0;
    }
    setCount(countOfProduct);
    setShopId(ShopCartId);
  }, [shopCart, item]);

  const onAddShopCart = (amount: number, remove?: boolean) => {
    if (Number(item?.count_buy_on_card) > count || remove) {
      onAddCart(
        {
          product_id: Number(item.id),
          quantity: amount,
        },
        item
      );
    }
  };
  const onRemoveShopCart = (amount: number) => {
    if (amount > 0) {
      onAddShopCart(amount, true);
    }
    if (amount === 0) {
      onDeleteCart({
        cart_id: Number(shopId),
        product_id: Number(item.id),
        quantity: 0,
      });
    }
  };
  return (
    <StyledProductCard>
      <Card>
        <Grid container>
          <Grid item xs={12} onClick={() => navigate(`/product/details/${item.id}`)}>
            {item?.category_id === categories?.meta?.tags_id?.kala_plus ||
            item?.category_id === categories?.meta?.tags_id?.kala2 ||
            item?.category_id === categories?.meta?.tags_id?.frosh_vizheh ? (
              <TopImgIcon>
                <Box
                  component="img"
                  src={
                    item?.category_id === categories?.meta?.tags_id?.kala_plus
                      ? infinityIcon
                      : item?.category_id === categories?.meta?.tags_id?.kala2
                      ? kala2Icon
                      : item?.category_id === categories?.meta?.tags_id?.frosh_vizheh
                      ? specialIcon
                      : ''
                  }
                  alt={'specialProduct'}
                  height={70}
                />
              </TopImgIcon>
            ) : null}

            <Box>
              <CardMedia component="img" image={item.images} alt={item.name} />
            </Box>
          </Grid>

          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            {count > 0 ? (
              <Box display={'flex'} flexDirection="row" sx={{ height: 33 }}>
                {addShopLoading ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  <>
                    <IconButton
                      size="small"
                      disabled={Number(item?.count_buy_on_card) <= count}
                      onClick={() => onAddShopCart(count + 1)}
                    >
                      <AddCircleOutlineIcon
                        color={Number(item?.count_buy_on_card) <= count ? 'disabled' : 'primary'}
                      />
                    </IconButton>
                    <Typography sx={{ minWidth: 30 }} mb={1} align="center">
                      {toFarsiNumber(String(count))}
                    </Typography>
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={() => onRemoveShopCart(count - 1)}
                    >
                      <RemoveCircleOutlineIcon color="primary" />
                    </IconButton>
                  </>
                )}
              </Box>
            ) : (
              <CButton
                variant="contained"
                size="small"
                sx={{ height: 32 }}
                loading={addShopLoading}
                onClick={() => {
                  onAddShopCart(1);
                }}
              >
                &nbsp; افزودن به سبد
              </CButton>
            )}
          </Grid>
        </Grid>
        <CardContent onClick={() => navigate(`/product/details/${item.id}`)}>
          <Typography fontWeight="bold" fontSize="small" align="center">
            {item.name}
          </Typography>
        </CardContent>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography fontWeight="bold" fontSize="medium" align="center" mb={1}>
            {thousandSeparator(item.sale_price)}
            &nbsp; ریال
          </Typography>
          <StyledLineThroughTypography
            fontWeight="bold"
            fontSize="small"
            align="center"
            color={'secondary'}
            onClick={() => navigate(`/product/details/${item.id}`)}
          >
            {thousandSeparator(item.product_price)}
            &nbsp; ریال
          </StyledLineThroughTypography>
        </Box>
      </Card>
    </StyledProductCard>
  );
};
