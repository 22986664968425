import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import LabelImportantOutlinedIcon from '@mui/icons-material/LabelImportantOutlined';
import { ICategory } from '../../../redux';
import { StyledCollapseFilter } from './CollapseFilter.styles';

interface IProps {
  active: number;
  data?: ICategory[];
  onChange: (id: number) => void;
}

export default function CollapseFilter({ active, data, onChange }: IProps) {
  const [activeCategory, setActiveCategory] = useState<number>(NaN);
  const [activeSubCategory, setActiveSubCategory] = useState<number>(NaN);

  useEffect(() => {
    if (typeof data !== 'undefined' && data?.length > 0) {
      let findParentCategoryIndex = data?.findIndex(
        (category) => Number(category.id) === Number(active)
      );
      if (
        findParentCategoryIndex > -1 &&
        data[findParentCategoryIndex]?.sub_categories?.length > 0
      ) {
        onChange(data[findParentCategoryIndex]?.sub_categories[0]?.id);
      } else {
        setActiveSubCategory(Number(active));
        data?.map((cat: ICategory) => {
          cat?.sub_categories.map((subCat) => {
            if (Number(subCat.id) === Number(active)) {
              setActiveCategory(cat.id);
            }
          });
        });
      }
    }
  }, [active, data]);
  useEffect(() => {
    if (typeof data !== 'undefined' && data?.length > 0) {
      if (data[0]?.sub_categories && data[0]?.sub_categories?.length > 0) {
        onChange(Number(data[0]?.sub_categories[0].id));
      }
    }
  }, [data]);

  return (
    <StyledCollapseFilter>
      {data?.map((list: ICategory) => {
        return (
          <List
            key={list.id}
            sx={{ width: '100%', maxWidth: 360 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton onClick={() => setActiveCategory(list.id)}>
              <ListItemIcon>
                <AppsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={list.name} />
              {activeCategory === list.id ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={activeCategory === list.id} timeout="auto" unmountOnExit>
              <List component="div">
                {list?.sub_categories?.map((subCat) => {
                  return (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      key={subCat.id}
                      onClick={() => onChange(Number(subCat.id))}
                    >
                      <ListItemIcon>
                        {activeSubCategory === subCat.id ? (
                          <LabelImportantOutlinedIcon />
                        ) : (
                          <LabelOutlinedIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={subCat.name} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </List>
        );
      })}
    </StyledCollapseFilter>
  );
}
