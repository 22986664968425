import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Box, Grid, IconButton, TableBody, TableRow, Typography } from '@mui/material';
import { thousandSeparator } from '../../../utils/price';
import Link from '@mui/material/Link';
import {
  IAddRemoveShopCartRequest,
  IPaymentRequest,
  addShop,
  deleteShop,
  selectTotalDiscountedPriceShopCart,
  // selectTotalPriceShopCart, Todo: don`t remove these comments after checking on server could be recommend
  selectTotalProfitShopCart,
  selectTotalQuantityShopCart,
  useAddShopCartMutation,
  useAppDispatch,
  useAppSelector,
  usePaymentMutation,
  useRemoveShopCartMutation,
} from '../../../redux';
import noImage from '../../../assets/img/emptyImage.svg';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  ResponsiveTable,
  StyledCartIconButton,
  StyledLabel,
  StyledPriceRow,
  StyledSubmitShopCart,
} from './CartTable.style';
import { CButton } from '../../../components/ui-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toFarsiNumber } from '../../../utils/helper';
import { useSnackbar } from 'notistack';

interface IColumn {
  id:
    | 'images'
    | 'name'
    // | 'product_code'  //TODO:don`t remove these comments after checking on server could be recommend
    | 'count_buy_on_card'
    // | 'product_price' //TODO:don`t remove these comments after checking on server could be recommend
    | 'sale_price'
    | 'total_price'
    | 'product_id'
    | 'id'
    | 'quantity';
  label: string;
  minWidth?: number;
  align?: 'center';
  customRender?: (value: number | string, row: ICartTableRow) => string | ReactElement;
}

export interface ICartTableRow {
  product_id: number;
  id: number;
  images: string;
  name: string;
  // product_code: string; //TODO:don`t remove these comments after checking on server could be recommend
  count_buy_on_card: number;
  // product_price: number; //TODO:don`t remove these comments after checking on server could be recommend
  sale_price: number;
  total_price: number;
  quantity: number;
}
export interface IErrPayment {
  data: {
    type: string;
    message: string;
  };
}

function createData(
  product_id: number,
  images: string,
  name: string,
  // product_code: string, //TODO:don`t remove these comments after checking on server could be recommend
  count_buy_on_card: number,
  // product_price: number, //TODO:don`t remove these comments after checking on server could be recommend
  sale_price: number,
  id: number,
  quantity: number
): ICartTableRow {
  const total_price = quantity * sale_price;
  return {
    product_id,
    images,
    name,
    // product_code, //TODO:don`t remove these comments after checking on server could be recommend
    count_buy_on_card,
    id,
    quantity,
    // product_price, //TODO:don`t remove these comments after checking on server could be recommend
    sale_price,
    total_price,
  };
}

export default function CartTable() {
  const shopCart = useAppSelector((state) => state.shopCart.data);
  const profile = useAppSelector((state) => state.profile.data);
  const purchaseBalance = useAppSelector((state) => state.purchaseBalance.data);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [addShopCart] = useAddShopCartMutation({});
  const [removeShopCart] = useRemoveShopCartMutation({});
  const [payment] = usePaymentMutation({});
  const [disablePayment, setDisablePayment] = useState(false);
  const [rows, setRows] = useState<ICartTableRow[]>([]);
  const shopCount = useSelector(selectTotalQuantityShopCart);
  // const totalPrice = useSelector(selectTotalPriceShopCart); Todo: don`t remove these comments after checking on server could be recommend
  const totalDiscountedPrice = useSelector(selectTotalDiscountedPriceShopCart);
  const totalProfit = useSelector(selectTotalProfitShopCart);
  const addItemInShopCart = (row: ICartTableRow, value: number) => {
    if (value <= Number(row.count_buy_on_card)) {
      onAddCart({
        product_id: Number(row.product_id),
        quantity: value + 1,
      });
    }
  };
  const columns: IColumn[] = useMemo(() => {
    return [
      {
        id: 'images',
        label: 'عکس محصول',
        minWidth: 100,
        customRender: (value, row) => (
          <Link color="text.primary" onClick={() => navigate('/product/details/' + row.product_id)}>
            <img
              src={String(value)}
              style={{
                width: 100,
                borderRadius: 15,
                cursor: 'pointer',
              }}
              alt="family-Shop"
            />
          </Link>
        ),
      },
      {
        id: 'name',
        label: 'نام',
        minWidth: 100,
        align: 'center',
        customRender: (value: number | string, row) => (
          <Box
            sx={{
              cursor: 'pointer',
            }}
          >
            <Link
              underline="hover"
              color="text.primary"
              onClick={() => navigate('/product/details/' + row.product_id)}
            >
              {value}
            </Link>
          </Box>
        ),
      },
      {
        id: 'quantity',
        label: 'تعداد',
        minWidth: 100,
        align: 'center',
        customRender: (value, row) => (
          <Box sx={{ display: { xs: 'flex' } }} alignItems={'end'} flexDirection={'column'}>
            <StyledCartIconButton>
              <IconButton
                size="small"
                aria-label="add basket"
                disabled={Number(row.count_buy_on_card) <= Number(value)}
                onClick={() => addItemInShopCart(row, Number(value))}
              >
                <AddIcon color="primary" />
              </IconButton>
              <Box component={'span'} sx={{ padding: '0 .5rem' }}>
                {toFarsiNumber(String(row.quantity))}
              </Box>
              <IconButton
                size="small"
                aria-label="remove basket"
                onClick={
                  value === 1
                    ? () =>
                        onDeleteCart({
                          cart_id: Number(row.id),
                          product_id: Number(row.product_id),
                          quantity: 0,
                        })
                    : () =>
                        onAddCart({
                          product_id: Number(row.product_id),
                          quantity: Number(value) - 1,
                        })
                }
              >
                <RemoveIcon color="primary" />
              </IconButton>
            </StyledCartIconButton>
            {Number(row.count_buy_on_card) <= Number(value) ? (
              <Typography variant="body1" color={'primary'} fontSize={'small'} mt={3}>
                {`تعداد محصول مجاز انتخابی ${toFarsiNumber(String(row.count_buy_on_card))} میباشد.`}
              </Typography>
            ) : null}
          </Box>
        ),
      },
      //TODO:don`t remove these comments after checking on server could be recommend
      // {
      //   id: 'product_code',
      //   label: 'کد محصول',
      //   minWidth: 100,
      //   align: 'center',
      //   customRender: (value: number | string) => <Box dir={'ltr'}>{value}</Box>,
      // },
      // {
      //   id: 'product_price',
      //   label: 'قیمت',
      //   minWidth: 100,
      //   align: 'center',
      //   customRender: (value: number | string) => thousandSeparator(Number(value)) + ' ریال ',
      // },
      {
        id: 'sale_price',
        label: 'قیمت با تخفیف',
        minWidth: 100,
        align: 'center',
        customRender: (value: number | string) => thousandSeparator(Number(value)) + ' ریال ',
      },
      {
        id: 'total_price',
        label: 'جمع',
        minWidth: 100,
        align: 'center',
        customRender: (value: number | string) => thousandSeparator(Number(value)) + ' ریال ',
      },
      {
        id: 'product_id',
        label: 'حذف',
        minWidth: 80,
        customRender: (value, row) => (
          <IconButton
            size="small"
            aria-label="delete basket"
            onClick={() =>
              onDeleteCart({
                cart_id: Number(row.id),
                product_id: Number(row.product_id),
                quantity: Number(0),
              })
            }
          >
            <DeleteForeverOutlinedIcon color="primary" />
          </IconButton>
        ),
      },
    ];
  }, []);
  const onAddCart = (data: IAddRemoveShopCartRequest) => {
    addShopCart({
      ...data,
    })
      .unwrap()
      .then((res) => {
        dispatch(addShop(data)); //Todo:add ProductObj
      })
      .catch((err) => {});
  };
  const onDeleteCart = (data: IAddRemoveShopCartRequest) => {
    removeShopCart({
      ...data,
    })
      .unwrap()
      .then((res) => {
        dispatch(deleteShop(data));
      })
      .catch((err) => {});
  };
  const windowLocation = (myExternalPageUrl: string) => {
    window.location.replace(myExternalPageUrl);
  };
  const onPayment = (data: IPaymentRequest) => {
    setDisablePayment(true);
    payment({
      ...data,
    })
      .unwrap()
      .then((res) => {
        const data = res[0]?.data; //TODO:after fix api response remove[]
        data?.payment === 'ipg' //payment==='ipg'||'manual'
          ? typeof data?.bank_url !== 'undefined' && windowLocation(data?.bank_url)
          : enqueueSnackbar(data?.message, {
              variant: 'success',
            });
        typeof data?.bank_url === 'undefined' &&
          setTimeout(() => navigate(`/orders/details/` + data?.order_id), 1000);
      })
      .catch((err) => {
        setDisablePayment(false);
      });
  };

  useEffect(() => {
    if (typeof shopCart !== 'undefined' && shopCart?.length > 0) {
      let transformRows: ICartTableRow[] = [];
      shopCart?.map((it) => {
        transformRows.push(
          //create newData for table
          createData(
            it?.product_id ?? 0,
            it?.product_data?.images ?? noImage,
            it?.product_data?.name ?? '-',
            // it?.product_data?.product_code ?? '-', //TODO:don`t remove these comments after checking on server could be recommend
            it?.product_data?.count_buy_on_card ?? 0,
            // it?.product_data?.product_price ?? 0, //TODO:don`t remove these comments after checking on server could be recommend
            it?.product_data?.sale_price ?? 0,
            it?.id ?? 0,
            it?.quantity ?? 0
          )
        );
        //create Row of table
        setRows(transformRows);
      });
    } else {
      setRows([]);
    }
  }, [shopCount]);

  return (
    <>
      <Paper sx={{ width: '100%', margin: '2rem 0' }}>
        <ResponsiveTable>
          <Table>
            <TableHead sx={{ marginBottom: 20 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 &&
                rows.map((row, index) => {
                  return (
                    <>
                      {row.quantity === 0 ? null : (
                        <TableRow tabIndex={-1} key={index}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id + index} align={column.align}>
                                {column.id !== 'images' ? (
                                  <StyledLabel>{column.label}:</StyledLabel>
                                ) : null}

                                {column.customRender ? column.customRender(value, row) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      )}
                    </>
                  );
                })}
              <StyledPriceRow>
                <TableCell>
                  تعداد کالای موجود در سبد خرید
                  <StyledLabel>
                    <Typography fontWeight={'bold'} dir={'ltr'}>
                      {toFarsiNumber(String(shopCount))}
                    </Typography>
                  </StyledLabel>
                </TableCell>
                {/* //TODO:don`t remove these comments after checking on server could be recommend */}
                {/* <TableCell align={'center'}> 
                  جمع قیمت
                  <StyledLabel>
                    <Typography fontWeight={'bold'}>
                      <Box component={'span'} dir={'ltr'} sx={{ marginLeft: 3 }}>
                        {thousandSeparator(totalPrice)}
                      </Box>
                      ریال
                    </Typography>
                  </StyledLabel>
                </TableCell> */}
                <TableCell align={'center'} colSpan={2}>
                  سود شما از خرید
                  <StyledLabel>
                    <Typography fontWeight={'bold'}>
                      <Box component={'span'} dir={'ltr'} sx={{ marginLeft: 3 }}>
                        {thousandSeparator(totalProfit)}
                      </Box>
                      ریال
                    </Typography>
                  </StyledLabel>
                </TableCell>
                <TableCell align={'center'} colSpan={3}>
                  جمع قیمت پرداختی با تخفیف
                  <StyledLabel>
                    <Typography fontWeight={'bold'}>
                      <Box component={'span'} dir={'ltr'} sx={{ marginLeft: 3 }}>
                        {thousandSeparator(totalDiscountedPrice)}
                      </Box>
                      ریال
                    </Typography>
                  </StyledLabel>
                </TableCell>
              </StyledPriceRow>
              <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
                <TableCell>
                  <Typography fontWeight={'bold'} dir={'ltr'}>
                    {toFarsiNumber(String(shopCount))}
                  </Typography>
                </TableCell>
                {/* //TODO:don`t remove these comments after checking on server could be recommend */}
                {/* <TableCell align={'center'}>
                  <Typography fontWeight={'bold'}>
                    <Box component={'span'} dir={'ltr'} sx={{ marginLeft: 3 }}>
                      {thousandSeparator(totalPrice)}
                    </Box>
                    ریال
                  </Typography>
                </TableCell> */}
                <TableCell align={'center'} colSpan={2}>
                  <Typography fontWeight={'bold'}>
                    <Box component={'span'} dir={'ltr'} sx={{ marginLeft: 3 }}>
                      {thousandSeparator(totalProfit)}
                    </Box>
                    ریال
                  </Typography>
                </TableCell>
                <TableCell align={'center'} colSpan={3}>
                  <Typography fontWeight={'bold'}>
                    <Box component={'span'} dir={'ltr'} sx={{ marginLeft: 3 }}>
                      {thousandSeparator(totalDiscountedPrice)}
                    </Box>
                    ریال
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ResponsiveTable>
      </Paper>
      {typeof shopCart !== 'undefined' && shopCart?.length > 0 ? (
        <Box mb={5} pb={5}>
          {profile?.company?.payment_type !== null ? (
            <Typography variant="h6" color={'error'} align="center" mb={5}>
              {profile?.company?.payment_type === 'manual'
                ? ' پس از ثبت سفارش خرید مهلت ارسال تصویر فیش بانکی 120 دقیقه می‌باشد.'
                : ' مهلت پرداخت مبلغ 20 دقیقه می‌باشد . '}
              درصورت عدم پرداخت در این مدت، سفارش شما باطل می گردد.
            </Typography>
          ) : null}
          <Grid
            container
            sx={{
              paddingX: { sm: 10, md: 13, lg: 20 },
              justifyContent: { sm: 'space-between', xs: 'center' },
            }}
          >
            <Grid item md={6} my={2}>
              <Typography fontWeight={'bold'} my={2} variant="h6">
                <Box component={'span'}>جمع قیمت کل:</Box>
                <Box component={'span'} dir={'ltr'} style={{ marginLeft: 3 }}>
                  {thousandSeparator(totalDiscountedPrice)}
                </Box>
                ریال
              </Typography>
              <Typography fontWeight={'bold'} variant="h6" color={'grey'}>
                <Box component={'span'}>سقف خرید:</Box>
                <Box component={'span'} dir={'ltr'} style={{ marginLeft: 3 }}>
                  {thousandSeparator(purchaseBalance?.purchase_limit ?? 0)}
                </Box>
                ریال
              </Typography>
            </Grid>
            {profile?.company?.payment_type !== null || profile?.company?.payment_state ? (
              <Grid item md={6} my={2} display={'flex'} alignItems={'center'}>
                <StyledSubmitShopCart>
                  {/*/Todo: after fix api should resolve this order_id:11 */}
                  <CButton
                    variant="contained"
                    color="primary"
                    disabled={disablePayment}
                    onClick={() => onPayment({ order_id: null })}
                  >
                    {profile?.company?.payment_type === 'manual' ? (
                      <Box component={'span'}>ثبت سفارش خرید</Box>
                    ) : (
                      <Box component={'span'}>تکمیل نهایی خرید</Box>
                    )}
                  </CButton>
                </StyledSubmitShopCart>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      ) : null}
    </>
  );
}
