import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { IProduct, useLazyGetProductsQuery } from '../../../redux';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import {
  ResultSearchBox,
  SearchScrollBox,
  StyledInputBase,
  StyledSearch,
  StyledSearchIconWrapper,
} from '../AppMenu.styles';
import { ResultSearchItem } from './ResultSearchItem';

export const SearchBox = () => {
  const navigate = useNavigate();
  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [productName, setProductName] = useState<string>('');
  const [searchData, setSearchData] = useState<IProduct[]>([]);
  const [getProducts, { isLoading: searchLoading }] = useLazyGetProductsQuery({});
  const onChangeSearchInput = (data: string) => {
    setProductName(data);
    if (data) {
      setShowSearchBox(true);
      getProducts({
        search: {
          searchFields: 'name',
          searchValue: data,
        },
        per_page: 100,
      })
        .unwrap()
        .then((res) => {
          setSearchData(res.data);
        })
        .catch((err) => console.log(err)); //Todo:when fix api show errors
    } else {
      setSearchData([]);
      setShowSearchBox(false);
    }
  };
  return (
    <StyledSearch>
      <StyledSearchIconWrapper>
        <SearchIcon />
      </StyledSearchIconWrapper>
      <StyledInputBase
        placeholder="جستجو..."
        inputProps={{ 'aria-label': 'search' }}
        value={productName}
        onChange={(event) => {
          onChangeSearchInput(event.target.value);
        }}
      />
      {showSearchBox ? (
        <ResultSearchBox>
          <SearchScrollBox scroll={searchData?.length >= 6 ? true : false}>
            {searchLoading ? (
              <Box display="flex" justifyContent="center" alignItems={'center'} mt={7}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {searchData?.length === 0 ? (
                  <Typography className="search-err" variant="body2" mt={5} mx={4}>
                    محصول مورد نظر یافت نشد.
                  </Typography>
                ) : (
                  searchData?.map((product) => {
                    return (
                      <ResultSearchItem
                        key={product.id}
                        item={product}
                        onclick={() => {
                          setShowSearchBox(false);
                          setProductName('');
                          navigate(`/product/details/${product.id}`);
                        }}
                      />
                    );
                  })
                )}
              </>
            )}
          </SearchScrollBox>
        </ResultSearchBox>
      ) : null}
    </StyledSearch>
  );
};
