import { Box, styled } from '@mui/material';

export const StyledBannerSlider = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0, 2),
  margin: theme.spacing(0, 0, 2),

  '.image-gallery-content': {
    '.image-gallery-slides': {
      borderRadius: theme.shape.borderRadius,
      '.image-gallery-description': {
        paddingBottom: theme.spacing(13),
        bottom: 0,
        background: 'unset',
      },
      '.image-gallery-image,img': {
        objectFit: 'cover',
        maxHeight: 500,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          height: 180,
          objectFit: 'fill',
        },
      },
    },
  },
  '.image-gallery-left-nav,.image-gallery-right-nav': {
    display: 'none',
  },
}));

export const StyledMessageSlider = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(2, 0, 6),
  '.image-gallery-description': {
    position: 'unset',
    background: 'unset',
    color: theme.palette.primary.main,
    textShadow: `1px 1px 2px ${theme.palette.secondary.light}`,
  },

  '.image-gallery-svg': {
    width: 30,
    [theme.breakpoints.down('sm')]: {
      width: 17,
    },
  },
  h6: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
}));
