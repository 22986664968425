import { Badge, BadgeProps, styled } from '@mui/material';

export const StyledAppNavigation = styled('div')(({ theme }) => ({
  '.MuiButtonBase-root': {
    '&:active ,&:hover': {
      color: theme.palette.primary.light,
    },
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  '.MuiSvgIcon-root ,.MuiBottomNavigationAction-label': {
    color: theme.palette.secondary.lighter,
  },
  '.MuiBottomNavigationAction-label': {
    color: theme.palette.secondary.lighter,
  },
  '.Mui-selected': {
    fontWeight: 500,
    color: theme.palette.secondary.light,
    fontSize: '0.75rem!important',
  },
}));
export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -4,
    top: 10,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3, 2),
  },
}));
