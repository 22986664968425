import { Box, Grid } from '@mui/material';
import {
  StyleImgSkeleton,
  StyledHeaderSkeleton,
  StyledParagraphSkeleton,
  StyledRoundedRecSkeleton,
} from '../ProductDetails.styles';

const ProductDetailsSkeleton = () => {
  return (
    <Grid container spacing={4} my={4}>
      <Grid item xs={12} mb={4} mx={5}>
        <StyledHeaderSkeleton animation="wave" />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
        <Box mt={5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <StyleImgSkeleton variant="circular" />
        </Box>

        <Box mx={5} mt={10} display={'flex'} justifyContent={'center'}>
          <StyledRoundedRecSkeleton variant="rectangular" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={9} display={'flex'}>
        <Box pt={5} pl={'2.2rem'} sx={{ minWidth: '100%' }}>
          <StyledHeaderSkeleton animation="wave" />

          <Box mt={12}>
            <StyledParagraphSkeleton animation="wave" />
            <Box mt={5} />
            <StyledParagraphSkeleton animation="wave" />
            <Box mt={5} />
            <StyledParagraphSkeleton animation="wave" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default ProductDetailsSkeleton;
