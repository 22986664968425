import { FormControl, type SxProps, type Theme } from '@mui/material';
import React, { ReactNode, forwardRef, type ReactElement } from 'react';
import {
  FormProvider,
  useForm,
  type FieldValues,
  type SubmitErrorHandler,
  type SubmitHandler,
  type UseFormProps,
  type UseFormReturn,
} from 'react-hook-form';

type functionalChildren<T extends FieldValues> = (props: UseFormReturn<T>) => JSX.Element;
type children<T extends FieldValues> = ReactElement<UseFormReturn> | functionalChildren<T>;
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type CUseFormProps<T extends FieldValues> = Optional<
  UseFormReturn<T>,
  | 'clearErrors'
  | 'control'
  | 'formState'
  | 'getFieldState'
  | 'getValues'
  | 'handleSubmit'
  | 'register'
  | 'reset'
  | 'resetField'
  | 'setError'
  | 'setFocus'
  | 'setValue'
  | 'trigger'
  | 'unregister'
  | 'watch'
>;

export type CFormFCProps<T extends object> = (props: CUseFormProps<T>) => JSX.Element;

export type CFormProps<T extends FieldValues> = {
  children?: ReactNode;
  onSubmit?: SubmitHandler<T>;
  id?: string;
  onInvalid?: SubmitErrorHandler<T>;
  sx?: SxProps<Theme>;
  formProviderProps?: UseFormReturn<T, any>;
} & UseFormProps<T>;

function _CForm<T extends FieldValues>(
  props: CFormProps<T>,
  ref: React.ForwardedRef<HTMLFormElement>,
) {
  const {
    children,
    onSubmit = () => undefined,
    onInvalid,
    formProviderProps,
    sx,
    id,
    ...useFormProps
  } = props;
  const Form = useForm<T>({
    mode: useFormProps.mode || 'onBlur',
    ...useFormProps,
  });

  if (formProviderProps) {
    Object.assign(Form, formProviderProps);
  }

  return (
    <FormProvider {...Form}>
      <FormControl
        component="form"
        onSubmit={Form.handleSubmit(onSubmit, onInvalid)}
        {...{ ref, id }}
        sx={{
          ...sx,
          width: '100%',
        }}
      >
        {children}
      </FormControl>
    </FormProvider>
  );
}

export const CForm = forwardRef(_CForm) as <T extends FieldValues>(
  props: CFormProps<T> & { ref?: React.ForwardedRef<HTMLFormElement> },
) => ReturnType<typeof _CForm>;
