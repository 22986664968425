import { Box } from '@mui/material';
import { useAppSelector } from '../../redux';
import { StyledEnamad } from './Footer.styles';

function EnamadBox() {
  const company = useAppSelector((state) => state.companyInfo.data);
  return (
    <StyledEnamad px={2}>
      {company?.enamad !== null ? (
        <Box
          width={190}
          dangerouslySetInnerHTML={{
            __html: typeof company !== 'undefined' ? company?.enamad : '',
          }}
        />
      ) : null}
    </StyledEnamad>
  );
}
export default EnamadBox;
