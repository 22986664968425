import { AdvertisingBanners } from '../AdvertisingBanners';
import specialIcon from '../../../../assets/img/specialIcon.png';
import specialTxt from '../../../../assets/img/specialTxt.png';
import specialTxtBox from '../../../../assets/img/specialTxtBox.png';
import { useNavigate } from 'react-router-dom';
import { IBannersBox } from '../BannersContainer';
import { useAppSelector } from '../../../../redux';

export const SpecialSalesBanner = ({ countOfBanners }: IBannersBox) => {
  const navigate = useNavigate();
  const categories = useAppSelector((state) => state.categories.data);
  return (
    <AdvertisingBanners
      background="linear-gradient(90deg, rgba(93,195,53,1) 0%, rgba(179,229,5,1) 35%, rgba(93,195,53,1) 100%)"
      iconSrc={specialIcon}
      txtImgSrc={specialTxt}
      txtBoxImgSrc={specialTxtBox}
      onclick={() => navigate(`/categories/${categories?.meta?.tags_id?.frosh_vizheh}`)}
      countOfBanners={countOfBanners}
    />
  );
};
