import { Box, styled } from '@mui/material';
export const StyledRoundedImg = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  span: {
    borderRadius: theme.shape.borderRadius,
  },
  img: {
    borderRadius: theme.shape.borderRadius,
    height: 250,
    minHeight: 250,
    [theme.breakpoints.down(823)]: {
      height: 'unset',
      minHeight: 150,
    },
  },
  '.lazy-load-image-background': {
    height: 250,
    minHeight: 250,
    [theme.breakpoints.down(823)]: {
      height: 'unset',
      minHeight: 150,
    },
  },
}));
