import { Box, Menu } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';

const INITIALSUBMENUWIDTH: number = 150;
interface ISearchScrollBox {
  scroll: boolean;
}

export const StyledAppMenu = styled(Box)(({ theme }) => ({
  width: '100%',
  top: '-30px',
  zIndex: theme.zIndex.fab,
  transition: 'all 1s',
  '.MuiPaper-root': {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    '.MuiToolbar-root': {
      margin: theme.spacing(0, 'auto'),
      width: '100%',
      padding: theme.spacing(0),
    },
  },
}));

export const StyledSubMenu = styled(Menu)(({ theme }) => ({
  width: '100%',
  '.MuiList-root': {
    minWidth: INITIALSUBMENUWIDTH,
  },
}));

export const StyledSearch = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
  margin: theme.spacing(0, 1),
  width: 'auto',
  [theme.breakpoints.between('xs', 'sm')]: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    width: '70%',
  },
  border: `1px solid ${theme.palette.common.black}`,
}));

export const StyledSearchIconWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  position: 'relative',
  color: `${theme.palette.common.black}`,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const SearchScrollBox = styled(Box)<ISearchScrollBox>(({ scroll }) => ({
  overflowY: scroll ? 'scroll' : 'hidden',
  minHeight: 200,
  maxHeight: 400,
  cursor: 'pointer',
  width: 280,
}));

export const ResultSearchBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 33,

  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: theme.spacing(4),
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.fab,
  p: {
    color: theme.palette.common.black,
  },
  '.description': {
    width: 180,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.search-err': {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const StyledButanLogoBox = styled(Box)(({ theme }) => ({
  width: 100,
  objectFit: 'contain',
  cursor: 'pointer',
  margin: theme.spacing(2, 1, 0),
}));

export const StyledMenuIcon = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  padding: theme.spacing(5, 1, 0),
}));
export const StyledPurchaseDropDownItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
