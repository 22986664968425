import React from 'react';
import { ICategory } from '../../../redux';
import { Box, Typography } from '@mui/material';

interface ICategoryMenuItems {
  item: ICategory;
  selectedCat?: number;
  onSelect: (item: ICategory) => void;
}

const CategoryItem = ({ item, selectedCat, onSelect }: ICategoryMenuItems) => {
  if (typeof item === 'undefined') return null;
  return (
    <Box
      onClick={() => {
        onSelect(item);
      }}
      onMouseOver={() => {
        onSelect(item);
      }}
      sx={(theme) =>
        selectedCat === item.id
          ? {
              backgroundColor: theme.palette.grey[500],
              color: theme.palette.common.white,
              borderRadius: theme.spacing(1),
            }
          : null
      }
    >
      <Typography py={2} px={3} variant="body2" fontWeight={'bold'}>
        {item.name}
      </Typography>
    </Box>
  );
};
export default CategoryItem;
