import { Paper } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
export const IconBoxStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(7, 3),
  textAlign: 'justify',
  height: '100%',
  'svg.MuiSvgIcon-root': {
    fontSize: '3rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
    },
  },
}));
