import { configureStore } from '@reduxjs/toolkit';
import shopSlice from './slices/shopSlice';
import profileSlice from './slices/ProfileSlice';
import categoriesSlice from './slices/categoriesSlice';
import companyInfoSlice from './slices/companyInfoSlice';
import { apiSlice } from './rtkQuery/api-slice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import purchaseBalanceSlice from './slices/purchaseBalanceSlice';

export const store = configureStore({
  reducer: {
    shopCart: shopSlice,
    profile: profileSlice,
    categories: categoriesSlice,
    companyInfo: companyInfoSlice,
    purchaseBalance: purchaseBalanceSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware as any),
  devTools: process.env.NODE_ENV !== 'production',
});
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
