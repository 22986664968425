import { SnackbarProvider, closeSnackbar } from 'notistack';
import { PropsWithChildren } from 'react';
import { NotistackComponents } from './SnackBar.styles';

const SnackBar = ({ children }: PropsWithChildren) => {
  return (
    <SnackbarProvider
      Components={NotistackComponents}
      SnackbarProps={{
        onClick: () => closeSnackbar(),
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackBar;
