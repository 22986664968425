import { PayloadAction } from '@reduxjs/toolkit';
import { createGenericSlice, GenericState } from '../GenericSlice';
import { IPurchaseBalance } from '../rtkQuery/api-types';

const slice = createGenericSlice({
  name: 'purchaseLimit',
  initialState: {
    status: 'loading',
    data: {},
  } as GenericState<IPurchaseBalance>,
  reducers: {
    setPurchaseBalance: (state, action: PayloadAction<IPurchaseBalance>) => {
      state.data = action.payload;
    },
  },
});

export const { setPurchaseBalance } = slice.actions;

export default slice.reducer;
