import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { StyledAppMenu, StyledButanLogoBox } from './AppMenu.styles';
import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { useAppSelector } from '../../redux';
const menuId = 'primary-account-menu';
export default function UnAuthAppMenu() {
  const company = useAppSelector((state) => state.companyInfo.data);
  const [scrollY, setScrollY] = useState<number>(0);
  const navigate = useNavigate();
  const SCROLLTRIGGERHEIGHT: number = 200;
  const listenToScroll = () => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    // Adding event listener when the component mounts
    window.addEventListener('scroll', listenToScroll);
    return () => {
      // Removing event listener when the component unmounts
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  return (
    <StyledAppMenu sx={scrollY >= SCROLLTRIGGERHEIGHT ? { position: 'fixed', top: 0 } : null}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid>
          <AppBar position="static" color={undefined}>
            <Container>
              <Toolbar sx={{ padding: 0 }}>
                <Box>
                  <IconButton
                    sx={{ p: 1 }}
                    size="large"
                    aria-label="login"
                    aria-controls={menuId}
                    onClick={() => navigate('/login')}
                    color="inherit"
                  >
                    <HomeIcon />
                  </IconButton>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <StyledButanLogoBox>
                  <Box
                    component="img"
                    width={100}
                    src={company?.logo}
                    alt={company?.name}
                    onClick={() => navigate('/login')}
                    px={2}
                  />
                </StyledButanLogoBox>
              </Toolbar>
            </Container>
          </AppBar>
        </Grid>
      </Box>
    </StyledAppMenu>
  );
}
