import React from 'react';
import { Box, Typography } from '@mui/material';
import { IconBoxStyled } from './IconBoxStyles';

interface IIconBox {
  title: string;
  text?: string;
  icon: JSX.Element;
}

const IconBox = ({ title, text, icon }: IIconBox) => {
  return (
    <IconBoxStyled>
      <Box>{icon}</Box>
      <Box my={3}>
        <Typography fontWeight="bold" color={'secondary'}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography color={'secondary'}>{text}</Typography>
      </Box>
    </IconBoxStyled>
  );
};
export default IconBox;
