import {
  IAddRemoveShopCartRequest,
  ICategoryRes,
  ICompanyInfo,
  IFaq,
  IGetMessageResponse,
  IGetResponse,
  ILoginRequest,
  ILoginResponse,
  IMessageSliderResponse,
  IOTPRequest,
  IOfferRequest,
  IOrder,
  IPayment,
  IPaymentRequest,
  IProduct,
  IProfile,
  IPurchaseBalance,
  IRequest,
  IShopCart,
  ISlidersResponse,
  PaginationResponse,
} from './api-types';
import { apiSlice as api } from './api-slice';
import { objectToFormData } from '../../utils/objects';

const urlVersion = '/api/v1/';

//TODO: separate users Api in new file

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    //// OTP ////
    getOTP: build.query<IGetResponse<any>, IOTPRequest>({
      query: (body) => ({
        url: `/api/v1/users/request-code/${body.mobile}`,
        method: 'GET',
      }),
    }),

    //// Login ////
    login: build.mutation<ILoginResponse, ILoginRequest>({
      query: (body) => ({
        url: urlVersion + 'users/login',
        method: 'POST',
        params: body,
      }),
      invalidatesTags: ['orders', 'products', 'shopCart', 'categories', 'profile'],
    }),
    //// faq ////
    getFaq: build.query<IGetResponse<IFaq[]>, IRequest<Body>>({
      query: (body) => ({
        url: urlVersion + 'users/faq',
        method: 'GET',
      }),
    }),
    //// companyInfo ////
    getCompaniesInfo: build.query<IGetResponse<ICompanyInfo[]>, IRequest<Body>>({
      query: (body) => ({
        url: '/api/companies',
        method: 'GET',
      }),
    }),
    //// feedback & offers ////
    offer: build.mutation<IGetMessageResponse, IOfferRequest>({
      query: (body) => ({
        url: urlVersion + 'users/feedback',
        method: 'POST',
        params: body,
      }),
    }),
    //// Products ////
    getProducts: build.query<PaginationResponse<IProduct[]>, IRequest<Body>>({
      query: (body) => ({
        url:
          body.search && body.pagination
            ? urlVersion +
              `products?page=${body.pagination.page}&search=${body.search.searchValue}&searchFields=${body.search.searchFields}`
            : !body?.search && body.pagination
            ? urlVersion + `products?page=${body.pagination.page}`
            : body?.search && !body.pagination
            ? urlVersion +
              `products?search=${body.search.searchValue}&searchFields=${body.search.searchFields}`
            : urlVersion + `products`,
        // params: body, //TODO : change all of all requests data for api products and type of IRequest for product
        method: 'GET',
        headers: {
          'per-page': String(body?.per_page),
        },
      }),
      providesTags: ['products'],
    }),

    //// Categories ////
    getCategories: build.query<ICategoryRes, IRequest<Body>>({
      query: (body) => ({
        url: urlVersion + `categories`,
        method: 'GET',
      }),
      providesTags: ['categories'],
    }),

    //// Sliders ////
    getSliders: build.query<ISlidersResponse, IRequest<Body>>({
      query: (body) => ({
        url: urlVersion + `sliders/banner`,
        method: 'GET',
      }),
      transformResponse: (response: ISlidersResponse) => {
        const sliders = response.data.map((obj) => {
          return {
            original: obj.image,
            thumbnail: obj.image,
            description: '',
          };
        });
        return { ...response, sliders };
      },
    }),
    getSlidersMessage: build.query<IMessageSliderResponse, IRequest<Body>>({
      query: (body) => ({
        url: urlVersion + `sliders/messages`,
        method: 'GET',
      }),
      transformResponse: (response: IMessageSliderResponse) => {
        const sliders = response.data.map((obj) => {
          return {
            original: '',
            thumbnail: '',
            description: obj.message,
          };
        });
        return { ...response, sliders };
      },
    }),

    //// shopCart ////
    getShopCart: build.query<IGetResponse<IShopCart[]>, IRequest<Body>>({
      query: (body) => ({
        url: urlVersion + 'carts',
        method: 'GET',
      }),
      providesTags: ['shopCart'],
    }),
    addShopCart: build.mutation<IGetResponse<IShopCart>, IAddRemoveShopCartRequest>({
      query: (body) => ({
        url: urlVersion + 'carts/add-to-cart',
        method: 'POST',
        params: body,
      }),
    }),
    removeShopCart: build.mutation<IGetMessageResponse, IAddRemoveShopCartRequest>({
      query: (body) => ({
        url: urlVersion + `carts/${body.cart_id}`,
        method: 'DELETE',
      }),
    }),
    //// orders ////
    getOrders: build.query<PaginationResponse<IOrder[]>, IRequest<Body>>({
      query: (body) => ({
        url:
          body.search && body.pagination
            ? urlVersion +
              `orders?page=${body.pagination.page}&search=${body.search.searchValue}&searchFields=${body.search.searchFields}`
            : !body?.search && body.pagination
            ? urlVersion + `orders?page=${body.pagination.page}`
            : urlVersion + `orders`,
        method: 'GET',
        headers: {
          'per-page': String(body?.per_page),
        },
      }),
      providesTags: ['orders'],
    }),

    //// profile ////
    getProfile: build.query<IGetResponse<IProfile>, IRequest<Body>>({
      query: (body) => ({
        url: urlVersion + 'users/profile',
        method: 'GET',
      }),
      providesTags: ['profile'],
    }),

    //// payment ////
    payment: build.mutation<IGetResponse<IPayment>[], IPaymentRequest>({
      query: (body) => ({
        url: urlVersion + 'orders/payment',
        method: 'POST',
      }),
      invalidatesTags: ['shopCart', 'orders'],
    }),
    //// uploadReceipt ////
    uploadReceiptFile: build.mutation<IGetResponse<IPayment>[], IPaymentRequest>({
      query: (body) => ({
        url: urlVersion + 'orders/payment/manually',
        method: 'POST',
        body: objectToFormData(body),
      }),
      invalidatesTags: ['orders'],
    }),

    ////purchaseLimit ////
    getPurchaseBalance: build.query<IGetResponse<IPurchaseBalance>, IRequest<Body>>({
      query: (body) => ({
        url: urlVersion + 'users/purchase-balance',
        method: 'GET',
      }),
      providesTags: ['shopCart'],
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as generatedApi };

export const {
  useLoginMutation,
  useOfferMutation,
  useAddShopCartMutation,
  useRemoveShopCartMutation,
  usePaymentMutation,
  useUploadReceiptFileMutation,
  useLazyGetOTPQuery,
  useLazyGetProductsQuery,
  useLazyGetShopCartQuery,
  useGetCategoriesQuery,
  useGetProductsQuery,
  useGetSlidersQuery,
  useGetShopCartQuery,
  useGetSlidersMessageQuery,
  useGetOrdersQuery,
  useGetFaqQuery,
  useGetProfileQuery,
  useGetCompaniesInfoQuery,
  useGetPurchaseBalanceQuery,
} = injectedRtkApi;
