import { Box, Container, Divider, Typography } from '@mui/material';
import { FooterLinks, StyledFooter } from './Footer.styles';
import { protectedRoutes } from '../../routes/protected';
import { publicRoutes } from '../../routes/public';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import EnamadBox from './EnamadBox';
import { getLocalStorageItem } from '../../utils/local-storage';
import { setCompanyInfo, useAppDispatch, useGetCompaniesInfoQuery } from '../../redux';
import { useEffect } from 'react';

const Footer = () => {
  const dispatch = useAppDispatch();
  const auth = getLocalStorageItem(`${window.location.host}-token`);
  const { data: companiesInfo } = useGetCompaniesInfoQuery({});
  useEffect(() => {
    companiesInfo?.data?.map((companyInfo) => {
      if (typeof companyInfo !== 'undefined') {
        if (companyInfo?.base_url === window.location.protocol + '//' + window.location.host) {
          dispatch(setCompanyInfo(companyInfo));
        }
      }
    });
  }, [companiesInfo]);
  return (
    <StyledFooter display={{ sm: 'block', xs: auth && 'none' }}>
      <Container>
        <FooterLinks className="login-footer">
          {auth ? (
            <>
              <Link component={RouterLink} to={'faq#help'}>
                راهنمای خرید
              </Link>
              {protectedRoutes?.map((route) => {
                return route.parent === 'subMenu' ? (
                  <Link key={route.id} component={RouterLink} to={route.path}>
                    {route.name}
                  </Link>
                ) : null;
              })}
            </>
          ) : (
            <>
              {publicRoutes?.map((route, index) => {
                return (
                  <>
                    {route?.name ? (
                      <Link key={index} component={RouterLink} to={route.path}>
                        {route.name}
                      </Link>
                    ) : null}
                  </>
                );
              })}
            </>
          )}
        </FooterLinks>
        <Divider />
        {/* Todo:recommend below after adding enamad  */}
        <Box display={{ sm: 'flex' }} justifyContent={'space-between'} px={1} mt={3}>
          <Box px={2}>
            <Typography fontWeight={'bold'} mb={1} color={(theme) => theme.palette.secondary.main}>
              فروشگاه خانوار
            </Typography>
            <Typography fontSize={'.8rem'} textAlign={'justify'} lineHeight={'1.5rem'}>
              این فروشگاه نه تنها ماهیت سودآور و انتفاعی ندارد، بلکه با تخفیفی که از طریق خرید عمده
              کالاها بدست می آورد و همچنین سوبسید شرکتی که بر نرخ خرید اعمال می نماید، تمامی کالاهای
              خود را با قیمتی به مراتب ارزانتر از نرخ بازار به همکاران خود عرضه می نماید. محور
              فعالیت این فروشگاه صرفا "کالاهای اساسی و پر‌مصرف" است که مورد استفاده عموم خانواده ها
              می‌باشد . بنابراین همچون سایر فروشگاههای اینترنتی، تنوع صرف در اقلام و برندها و گسترش
              سبد کالاها، جزو سیاستهای آن نمی‌باشد و همانگونه که بیان شد، صرفا محدود به فروش کالاهای
              اساسی می‌باشد.
            </Typography>
          </Box>
          <Box
            display={{ xs: 'flex', sm: 'block' }}
            justifyContent={'center'}
            alignItems={'center'}
            mt={{ xs: 3, sm: 0 }}
          >
            <EnamadBox />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={5} pb={3}>
          <Typography fontWeight={'bold'} fontSize={'.8rem'}>
            © 1402 کلیه حقوق این سایت متعلق به بوتان است.
          </Typography>
        </Box>
      </Container>
    </StyledFooter>
  );
};
export default Footer;
