import React, { useEffect, useMemo, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  faIR,
} from '@mui/x-data-grid';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useAppSelector, useGetOrdersQuery } from '../../redux';
import { useLoading } from '../../components/loading';
import { thousandSeparator } from '../../utils/price';
import { OrderState, toFarsiNumber } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import CustomNoRowsOverlay from './components/NoRows';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { CButton } from '../../components/ui-components';
import { getLocalStorageItem } from '../../utils/local-storage';

interface IOrdersRows {
  id: number;
  order_id: number;
  state: string;
  total_price: string;
  created_at: string;
}

function createOrdersTableData(
  id: number,
  order_id: number,
  state: string,
  total_price: string,
  created_at: string
): IOrdersRows {
  return {
    id,
    order_id,
    state,
    total_price,
    created_at,
  };
}
const Orders = () => {
  const PER_PAGE = 1000;
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<IOrdersRows[]>([]);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();
  const existingTheme = useTheme();
  const profile = useAppSelector((state) => state.profile.data);
  const theme = useMemo(
    () =>
      createTheme({}, faIR, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme]
  );
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'ردیف',
        filterable: false,
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Box px={3}>
            {toFarsiNumber(String(params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1))}
          </Box>
        ),
      },
      {
        field: 'order_id',
        headerName: 'شماره فاکتور',
        type: 'number',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Button variant="text" color="info" onClick={() => navigate(`details/${params.id}`)}>
            {toFarsiNumber(String(params.row.order_id))}
          </Button>
        ),
      },
      {
        field: 'total_price',
        headerName: 'مبلغ (ریال)',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams<number>) => {
          if (params.value == null) {
            return '';
          }
          return `${thousandSeparator(Number(params.value))} ریال`;
        },
      },

      {
        field: 'state',
        headerName: 'وضعیت',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => OrderState(params.row.state),
      },
      {
        field: 'created_at',
        headerName: 'تاریخ ثبت',
        width: 150,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        renderCell: (params: GridRenderCellParams<any, Date>) => {
          if (params.value == null) {
            return '';
          }
          return <span dir="ltr">{new Date(params.value).toLocaleString('fa-IR')}</span>;
        },
      },
      {
        field: 'action',
        headerName: 'عملیات',
        sortable: false,
        filterable: false,
        headerAlign: 'center',
        width: 430,

        renderCell: (params: GridRenderCellParams<any>) => (
          <>
            <CButton
              color="info"
              variant="text"
              onClick={() => navigate(`details/${params.row.id}`)}
              size="small"
            >
              نمایش جزییات
            </CButton>
            {/* //Todo:paayment_type==='ipg' */}
            {profile?.company?.payment_state && params.row.state === 'pending' ? (
              <>
                {profile?.company?.payment_type !== 'manual' ? (
                  <CButton
                    color="warning"
                    variant="text"
                    onClick={() => navigate('/shop-cart')}
                    size="small"
                  >
                    پرداخت
                  </CButton>
                ) : null}

                {profile?.company?.payment_state && profile?.company?.payment_type === 'manual' ? ( //if payment_type=manual user only pays offline //if payment_state=false user cannot buy anything
                  <CButton
                    color="primary"
                    variant="text"
                    onClick={() => navigate(`details/${params.row.id}`)}
                    size="small"
                  >
                    آپلود فیش واریزی
                  </CButton>
                ) : null}
              </>
            ) : null}
          </>
        ),
      },
    ];
  }, [rows, navigate]);

  const { data: orders, isLoading } = useGetOrdersQuery({
    pagination: {
      page: page,
    },
    per_page: PER_PAGE,
  });

  useEffect(() => {
    if (isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (typeof orders !== 'undefined') {
        let transformRows: IOrdersRows[] = [];
        orders?.data?.length > 0 &&
          orders?.data?.map((item) => {
            transformRows.push(
              //create newData for table
              createOrdersTableData(
                item?.id ?? 0,
                item?.subOrder[0]?.order_id ?? 0,
                item?.state ?? '-',
                item?.total_price ?? 0,
                item?.created_at ?? '-'
              )
            );
          });
        setRows(transformRows);
      }
    }
  }, [isLoading]);

  return (
    <Box px={2}>
      <Typography my={5} variant="h6">
        تاریخچه سفارشات
      </Typography>

      <Paper>
        <ThemeProvider theme={theme}>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: page, pageSize: 10 },
              },
            }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={isLoading}
            pageSizeOptions={[5, 10, 15, 20]}
            onPaginationModelChange={(e) => setPage(e.page + 1)}
          />
        </ThemeProvider>
      </Paper>
    </Box>
  );
};
export default Orders;
