import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { CTextField, CButton } from '../../components/ui-components';
import { Controller, useForm } from 'react-hook-form';
import { IOfferRequest, useOfferMutation } from '../../redux';
import { useSnackbar } from 'notistack';

const Offers = () => {
  const { handleSubmit, control } = useForm<IOfferRequest>();
  const [offer, { isLoading: offerLoading }] = useOfferMutation({});
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = (data: IOfferRequest) => {
    offer({
      ...data,
    })
      .unwrap()
      .then((res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      })
      .catch((err) => {});
  };

  return (
    <>
      <Box my={5} py={4}>
        <Typography align="center" fontWeight={'bold'}>
          همکار گرامی، برای ارتقای فرایندهای فروشگاه، لطفا سئوالات، پیشنهادات و انتقادات خود را با
          مسئولان فروشگاه در میان بگذارید.
        </Typography>
      </Box>

      <Grid
        item
        sm={8}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        container
        direction="column"
        justifyItems="center"
        gap={2}
        p={10}
        mx="auto"
        bgcolor="white"
        borderRadius={2}
      >
        <Typography variant="h6" align="center">
          ثبت پیشنهادات و شکایات
        </Typography>
        <Controller
          name={'title'}
          control={control}
          render={({ field }) => (
            <CTextField
              {...field}
              margin="dense"
              label="عنوان"
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              required={true}
            />
          )}
        />
        <Controller
          name={'body'}
          control={control}
          render={({ field }) => (
            <CTextField
              {...field}
              margin="dense"
              label="توضیحات"
              multiline
              minRows={8}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              required={true}
            />
          )}
        />
        <CButton
          sx={{ marginTop: 2 }}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          disabled={offerLoading}
          loading={offerLoading}
        >
          ثبت
        </CButton>
      </Grid>
    </>
  );
};
export default Offers;
