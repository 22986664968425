import React from 'react';
import { Grid } from '@mui/material';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import IconBox from './components/IconBox';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { useAppSelector } from '../../redux';
import { StyledRoundedImg } from '../Products/components/RoundedImg.styles';
import LazyImg from '../../components/loading/LazyImg';
import contactUsBanner from '../../assets/img/contactUsBanner.jpg';
import PlaceholderImg from '../../assets/img/lazyBanners.png';

const ContactUs = () => {
  const company = useAppSelector((state) => state.companyInfo.data);

  return (
    <Grid container spacing={{ xs: 2, md: 4 }}>
      <Grid item xl={12} lg={12} sm={12} xs={12}>
        <StyledRoundedImg>
          <LazyImg src={contactUsBanner} alt={'contact-us'} PlaceholderImg={PlaceholderImg} />
        </StyledRoundedImg>
      </Grid>
      <Grid container sx={{ display: 'flex', flexFlow: 'row wrap' }}>
        <Grid item xs={12} sm={4} md={4} pl={2} pr={1} my={2}>
          <IconBox title="آدرس" text={company?.address} icon={<PlaceOutlinedIcon />} />
        </Grid>
        <Grid item xs={12} sm={4} md={4} px={2} my={2}>
          <IconBox title="آدرس ایمیل" text={company?.email} icon={<MailOutlinedIcon />} />
        </Grid>
        <Grid item xs={12} sm={4} md={4} px={1} my={2}>
          <IconBox title="تلفن" text={company?.phone} icon={<PhoneAndroidOutlinedIcon />} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ContactUs;
