import { AdvertisingBanners } from '../AdvertisingBanners';
import kala2Icon from '../../../../assets/img/kala2Icon.png';
import kala2Txt from '../../../../assets/img/kala2Txt.png';
import kala2TxtBox from '../../../../assets/img/kala2TxtBox.png';
import { useNavigate } from 'react-router-dom';
import { IBannersBox } from '../BannersContainer';
import { useAppSelector } from '../../../../redux';

export const Kala2Banner = ({ countOfBanners }: IBannersBox) => {
  const navigate = useNavigate();
  const categories = useAppSelector((state) => state.categories.data);
  return (
    <AdvertisingBanners
      background="linear-gradient(90deg, rgba(128,14,197,1) 0%, rgba(173,25,235,1) 50%, rgba(128,14,197,1) 100%)"
      iconSrc={kala2Icon}
      txtImgSrc={kala2Txt}
      txtBoxImgSrc={kala2TxtBox}
      onclick={() => navigate(`/categories/${categories?.meta?.tags_id?.kala2}`)}
      countOfBanners={countOfBanners}
    />
  );
};
