import { Box, Grid } from '@mui/material';

type ImgBox = {
  src: string;
};
export const ImageBox = ({ src }: ImgBox) => {
  return (
    <Grid
      item
      md={4}
      sm={4}
      xs={12}
      my={2}
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      py={2}
    >
      <Box component={'img'} src={src} sx={{ width: { xs: 150, sm: 150, lg: 200 } }} />
    </Grid>
  );
};
