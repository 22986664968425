import { useNavigate, useParams } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { CButton } from '../../components/ui-components';
import { useGetShopCartQuery } from '../../redux';

const PaymentResult = () => {
  const params = useParams<string>();
  const navigate = useNavigate();
  const { refetch } = useGetShopCartQuery({});
  const [paymentStatus, setPaymentStatus] = useState<string>(); //success or fail
  useEffect(() => {
    setPaymentStatus(params?.payment_status);
    refetch();
  }, [params?.payment_status]);

  return (
    <Box
      sx={{ height: '60vh' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection={'column'}>
        {paymentStatus === 'success' ? (
          <VerifiedIcon sx={{ fontSize: '8rem' }} color="success" />
        ) : (
          <DangerousIcon sx={{ fontSize: '8rem' }} color="error" />
        )}
        <Typography fontWeight={'bold'} variant="h6" my={4}>
          {paymentStatus === 'success' ? 'پرداخت موفق' : 'پرداخت ناموفق'}
        </Typography>
        <Typography fontWeight={'bold'} my={2}>
          {paymentStatus === 'success'
            ? 'سفارش شما با موفقیت ثبت شد'
            : ' متاسفانه سفارش شما ثبت نشد'}
        </Typography>
      </Box>
      <Box>
        <CButton
          sx={{ marginTop: 2 }}
          fullWidth
          type="button"
          color="info"
          onClick={() => navigate('/')}
        >
          بازگشت به صفحه اصلی
        </CButton>
      </Box>
    </Box>
  );
};
export default PaymentResult;
