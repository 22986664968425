import { TextFieldProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { ForwardedRef, forwardRef, HTMLInputTypeAttribute, useState } from 'react';
import {
  CHelperTextStyle,
  CHelperTextWrapperStyle,
  CTextFieldRootStyle,
} from './CTextField.styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export type CTextFieldProps = Omit<TextFieldProps, 'ref'> & {
  dataTestId?: string;
};

export const CTextField = forwardRef(
  <T extends HTMLInputElement>(
    {
      type,
      helperText,
      InputProps,
      inputProps,
      size = 'small',
      multiline,
      ...props
    }: CTextFieldProps,
    ref: ForwardedRef<T>,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const typeSelector = (type?: HTMLInputTypeAttribute): HTMLInputTypeAttribute => {
      if (!type) return 'text';
      switch (type) {
        case 'password':
          if (showPassword) return 'text';
          return 'password';
        default:
          return type;
      }
    };
    return (
      <CTextFieldRootStyle
        size={size}
        type={typeSelector(type)}
        inputProps={{
          ...(type === 'number' && {
            inputMode: 'numeric',
          }),
          ...inputProps,
        }}
        multiline={multiline}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {type === 'password' && (
                <IconButton size="small" onClick={handleClickShowPassword}>
                  {showPassword ? (
                    <VisibilityIcon fontSize="small" />
                  ) : (
                    <VisibilityOffIcon fontSize="small" />
                  )}
                </IconButton>
              )}
              {InputProps?.endAdornment}
            </InputAdornment>
          ),
          startAdornment: InputProps?.startAdornment,
        }}
        ref={ref}
        helperText={
          helperText && (
            <CHelperTextWrapperStyle sx={{ color: props.error ? 'error.main' : 'grey.900' }}>
              <CHelperTextStyle>{helperText}</CHelperTextStyle>
            </CHelperTextWrapperStyle>
          )
        }
        {...props}
      />
    );
  },
);
