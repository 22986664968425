import { Box, Grid } from '@mui/material';

interface IAdvertisingBanners {
  iconSrc: string;
  txtImgSrc: string;
  txtBoxImgSrc: string;
  onclick: () => void;
  background: string;
  countOfBanners?: number;
}
export const AdvertisingBanners = ({
  iconSrc,
  txtImgSrc,
  txtBoxImgSrc,
  onclick,
  background,
  countOfBanners,
}: IAdvertisingBanners) => {
  return (
    <Grid
      container
      sx={{
        width: '100%',
        minHeight: countOfBanners === 3 ? { md: 200, xs: 100 } : { md: 250, xs: 100 },
        borderRadius: 1,
        cursor: 'pointer',
        justifyContent: { sm: 'center', xs: 'space-around' },
        alignItems: 'center',
      }}
      p={4}
      onClick={onclick}
      style={{
        background: background,
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Box
          component={'img'}
          src={iconSrc}
          sx={{
            width: countOfBanners === 3 ? { xs: 100, sm: 120, lg: 180 } : { xs: 100, sm: 200 },
          }}
        />
      </Box>

      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        px={4}
      >
        <Box
          component={'img'}
          src={txtImgSrc}
          sx={{
            width:
              countOfBanners === 3
                ? { xs: 80, sm: 100, xl: 100 }
                : { xs: 100, sm: 100, md: 150, lg: 250 },
          }}
          mb={4}
        />
        <Box
          component={'img'}
          src={txtBoxImgSrc}
          sx={{
            width:
              countOfBanners === 3
                ? { xs: 80, sm: 130, lg: 130 }
                : { xs: 80, sm: 100, md: 150, lg: 250 },
          }}
        />
      </Box>
    </Grid>
  );
};
