import { IconButton } from '@mui/material';
import { StyledMenuIcon } from '../AppMenu.styles';

interface IMenuIcon {
  element: React.ReactNode;
  menuId?: string;
  label: string;
  haspopup?: true;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}
const MenuIconButton = ({ element, menuId, label, haspopup, onClick }: IMenuIcon) => {
  return (
    <StyledMenuIcon>
      <IconButton
        size="large"
        edge="end"
        aria-label={label}
        aria-controls={menuId}
        aria-haspopup={haspopup}
        onClick={onClick}
        color="inherit"
      >
        {element}
      </IconButton>
    </StyledMenuIcon>
  );
};
export default MenuIconButton;
