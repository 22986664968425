import { styled, Box } from '@mui/material';

export const StyledStateTitle = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));
export const StyledBarcode = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: theme.spacing(1, 0),
  svg: {
    width: 150,
    height: 80,
  },
}));
