import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { FullScreenAdvertisingBanner } from './FullScreenAdvertisingBanner';
import { IShowBanners } from '../Product';
import unlimitedIcon from '../../../assets/img/unlimitedIcon.png';
import unlimitedTxt from '../../../assets/img/unlimitedTxt.png';
import unlimitedTxtBox from '../../../assets/img/unlimitedTxtBox.png';
import specialIcon from '../../../assets/img/specialIcon.png';
import specialTxt from '../../../assets/img/specialTxt.png';
import specialTxtBox from '../../../assets/img/specialTxtBox.png';
import kala2Icon from '../../../assets/img/kala2Icon.png';
import kala2Txt from '../../../assets/img/kala2Txt.png';
import kala2TxtBox from '../../../assets/img/kala2TxtBox.png';
import { useNavigate } from 'react-router-dom';
import { Kala2Banner } from './‌banners/Kala2Banner';
import { SpecialSalesBanner } from './‌banners/SpecialSalesBanner';
import { KalaPlusBanner } from './‌banners/KalaPlusBanner';
import { useAppSelector } from '../../../redux';

interface IBannersContainer {
  showAdBanners: IShowBanners;
}
export interface IBannersBox {
  countOfBanners?: number;
}
export const BannersContainer = ({ showAdBanners }: IBannersContainer) => {
  const navigate = useNavigate();
  const categories = useAppSelector((state) => state.categories.data);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  return (
    <Box px={2}>
      {showAdBanners?.kala2 && showAdBanners?.unlimited && showAdBanners?.special ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Kala2Banner countOfBanners={3} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <KalaPlusBanner countOfBanners={3} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SpecialSalesBanner countOfBanners={3} />
          </Grid>
        </Grid>
      ) : showAdBanners?.kala2 && showAdBanners?.unlimited && !showAdBanners?.special ? (
        <Grid container spacing={3} display={'flex'} alignItems={'stretch'}>
          <Grid item xs={12} sm={6} display={'flex'}>
            <Kala2Banner />
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'}>
            <KalaPlusBanner />
          </Grid>
        </Grid>
      ) : showAdBanners?.kala2 && !showAdBanners?.unlimited && showAdBanners?.special ? (
        <Grid container spacing={3} display={'flex'} alignItems={'stretch'}>
          <Grid item xs={12} sm={6} display={'flex'}>
            <Kala2Banner />
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'}>
            <SpecialSalesBanner />
          </Grid>
        </Grid>
      ) : !showAdBanners?.kala2 && showAdBanners?.unlimited && showAdBanners?.special ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} display={'flex'}>
            <KalaPlusBanner />
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'}>
            <SpecialSalesBanner />
          </Grid>
        </Grid>
      ) : showAdBanners.kala2 ? (
        isMobile ? (
          <Kala2Banner />
        ) : (
          <FullScreenAdvertisingBanner
            background="linear-gradient(90deg, rgba(128,14,197,1) 0%, rgba(173,25,235,1) 50%, rgba(128,14,197,1) 100%)"
            iconSrc={kala2Icon}
            txtImgSrc={kala2Txt}
            txtBoxImgSrc={kala2TxtBox}
            onclick={() => navigate(`/categories/${categories?.meta?.tags_id?.kala2}`)}
          />
        )
      ) : showAdBanners.unlimited ? (
        isMobile ? (
          <KalaPlusBanner />
        ) : (
          <FullScreenAdvertisingBanner
            background="linear-gradient(90deg, rgba(247,60,36,1) 0%, rgba(242,101,34,1) 50%, rgba(247,60,36,1) 100%)"
            iconSrc={unlimitedIcon}
            txtImgSrc={unlimitedTxt}
            txtBoxImgSrc={unlimitedTxtBox}
            onclick={() => navigate(`/categories/${categories?.meta?.tags_id?.kala_plus}`)}
          />
        )
      ) : showAdBanners.special ? (
        isMobile ? (
          <SpecialSalesBanner />
        ) : (
          <FullScreenAdvertisingBanner
            background="linear-gradient(90deg, rgba(93,195,53,1) 0%, rgba(179,229,5,1) 35%, rgba(93,195,53,1) 100%)"
            iconSrc={specialIcon}
            txtImgSrc={specialTxt}
            txtBoxImgSrc={specialTxtBox}
            onclick={() => navigate(`/categories/${categories?.meta?.tags_id?.frosh_vizheh}}`)}
          />
        )
      ) : null}
    </Box>
  );
};
