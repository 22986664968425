import { Route } from './types';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ProductDetails from '../pages/Products/ProductDetails';
import Categories from '../pages/Categories/Categories';
import Product from '../pages/Products/Product';
import ShopCart from '../pages/ShopCart/ShopCart';
import Orders from '../pages/Orders/Orders';
import OrdersDetails from '../pages/Orders/OrdersDetails';
import AboutUs from '../pages/AboutUs/AboutUs';
import Faq from '../pages/Faq/Faq';
import Rules from '../pages/Rules/Rules';
import ContactUs from '../pages/ContactUs/ContactUs';
import Offers from '../pages/Offers/Offers';
import PersonIcon from '@mui/icons-material/Person';
import Profile from '../pages/Profile/Profile';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import GavelIcon from '@mui/icons-material/Gavel';
import RateReviewIcon from '@mui/icons-material/RateReview';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import InfoIcon from '@mui/icons-material/Info';
import PaymentResult from '../pages/PaymentResult/PaymentResult';
import Page404 from '../pages/404/Page404';
import Page403 from '../pages/403/Page403';

export const protectedRoutes: Route[] = [
  {
    id: 1,
    path: '/',
    icon: <HomeOutlinedIcon />,
    element: <Product />,
    name: 'خانه',
  },
  {
    id: 2,
    path: '/product/details/:id',
    element: <ProductDetails />,
    hidden: true,
  },
  {
    id: 3,
    path: '/categories/:id',
    icon: <AppsOutlinedIcon />,
    element: <Categories />,
    name: 'دسته بندی',
  },
  {
    id: 4,
    path: '/shop-cart',
    icon: <ShoppingBasketOutlinedIcon />,
    element: <ShopCart />,
    name: 'سبد خرید',
  },
  {
    id: 5,
    path: '/orders',
    element: <Orders />,
    hidden: true,
    name: 'سفارشات',
    parent: 'profile',
    icon: <ChecklistRtlIcon />,
  },
  {
    id: 6,
    path: '/orders/details/:id',
    element: <OrdersDetails />,
    hidden: true,
  },
  {
    id: 7,
    path: '/faq',
    hidden: true,
    element: <Faq />,
    name: 'پرسش‌های متداول',
    parent: 'subMenu',
    icon: <HelpCenterIcon />,
  },

  {
    id: 9,
    path: '/rules',
    hidden: true,
    element: <Rules />,
    name: 'قوانین و مقررات',
    parent: 'subMenu',
    icon: <GavelIcon />,
  },

  {
    id: 11,
    path: '/offers',
    hidden: true,
    element: <Offers />,
    name: 'شکایات و پیشنهادات',
    parent: 'subMenu',
    icon: <RateReviewIcon />,
  },
  {
    id: 10,
    path: '/contact-us',
    hidden: true,
    element: <ContactUs />,
    name: 'تماس با ما',
    parent: 'subMenu',
    icon: <PhoneEnabledIcon />,
  },
  {
    id: 8,
    path: '/about-us',
    hidden: true,
    element: <AboutUs />,
    parent: 'subMenu',
    name: 'درباره ما',
    icon: <InfoIcon />,
  },
  {
    id: 12,
    path: '/profile',
    icon: <PersonIcon />,
    element: <Profile />,
    name: 'پروفایل',
  },
  {
    id: 13,
    path: '/payment/:payment_status',
    element: <PaymentResult />,
    hidden: true,
  },
  { id: 14, path: '*', element: <Page404 />, hidden: true },
  { id: 15, path: '*', element: <Page403 />, hidden: true },
];
