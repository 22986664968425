import { PaletteColor } from '@mui/material/styles';
import {
  PaletteOptions,
  TypeBackground,
  CommonColors,
  TypeText,
} from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter: string;
  }
}

const primary: PaletteColor = {
  lighter: '#ffeaed',
  light: '#fb9591',
  main: '#ed1c24',
  dark: '#9e0410',
  contrastText: '#fff',
};

export const secondary: PaletteColor = {
  lighter: '#BEC0D1',
  light: '#7E7FA1',
  main: '#0C0F20 ',
  dark: '#04060c',
  contrastText: '#fff',
};

export const background: TypeBackground = {
  default: '#F1F4F9',
  paper: '#FCFBFE',
};

const common: CommonColors = {
  black: '#0C0F20',
  white: '#FFFFFF',
};

export const butaneRed: PaletteColor = {
  lighter: '#ff611eda',
  light: '#ff0000dc',
  main: '#ff0000',
  dark: '#ff0000da',
  contrastText: '#fff',
};

const text: TypeText = {
  primary: '#0C0F20 ',
  secondary: '#fff',
  disabled: '#BEC0D1',
};

export const familyShopPalette: Partial<PaletteOptions> = {
  mode: 'light',
  primary,
  secondary,
  background,
  common,
  text,
};
