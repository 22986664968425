import React, { useEffect, useState } from 'react';
import { ICategory } from '../../../redux';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Box, ClickAwayListener, Grid, Typography } from '@mui/material';
import { CategoryItems, DropDownBox, DropDownContainer } from './CategoryItems.styles';
import CategoryItem from './CategoryItem';
import SubCategoryItem from './SubCategoryItem';

interface ICategoryMenuItems {
  items?: ICategory[];
  activeItem?: ICategory;
}

const CategoryMenuItems = ({ items, activeItem }: ICategoryMenuItems) => {
  const navigate = useNavigate();
  const [selectedSubCat, setSelectedSubCat] = useState<ICategory[]>([]);
  const [selectedCat, setSelectedCat] = useState<number>(NaN);
  const [open, setOpen] = useState(false);
  const onSelectCategory = (item: ICategory) => {
    setSelectedSubCat(item?.sub_categories);
    setSelectedCat(item.id);
  };
  const onSelectSubCategory = (item: ICategory) => {
    navigate(`/categories/${item.id}`);
    setOpen(false);
  };
  useEffect(() => {
    if (activeItem && open) {
      setSelectedCat(activeItem.id);
      setSelectedSubCat(activeItem.sub_categories);
    }
  }, [activeItem, open]);
  if (typeof items === 'undefined') return null;
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <CategoryItems>
        <Box className="category-trigger" onClick={() => setOpen(!open)}>
          <Typography variant="body2" fontWeight={'bold'}>
            دسته بندی کالاها
          </Typography>
          <KeyboardArrowDownOutlinedIcon />
        </Box>
        {open ? (
          <DropDownBox className="dropdown-box">
            <DropDownContainer container className="dropdown-container" p={3}>
              <Grid
                item
                xl={4}
                lg={5}
                md={5}
                px={2}
                borderRight={(theme) => `1px solid ${theme.palette.grey[400]}`}
              >
                {items?.length > 0 &&
                  items?.map((cat) => {
                    return (
                      <CategoryItem
                        key={cat.id}
                        onSelect={onSelectCategory}
                        item={cat}
                        selectedCat={selectedCat}
                      />
                    );
                  })}
              </Grid>
              <Grid item xl={8} lg={7} md={7}>
                {selectedSubCat?.length > 0 ? (
                  <Grid container>
                    {selectedSubCat?.map((subCat) => {
                      return (
                        <SubCategoryItem
                          key={subCat.id}
                          item={subCat}
                          onSelect={onSelectSubCategory}
                        />
                      );
                    })}
                  </Grid>
                ) : (
                  <Typography px={5} variant="body2" color={'error'}>
                    کالایی موجود نیست.
                  </Typography>
                )}
              </Grid>
            </DropDownContainer>
          </DropDownBox>
        ) : null}
      </CategoryItems>
    </ClickAwayListener>
  );
};
export default CategoryMenuItems;
