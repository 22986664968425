import { Box, Skeleton } from '@mui/material';
import ReactImageGallery from 'react-image-gallery';
import { useGetSlidersQuery } from '../../../redux';
import { StyledBannerSlider } from './BannerSlider.styles';
import banner from '../../../assets/img/banner.jpg';

export const BannerSlider = () => {
  const { data, isLoading } = useGetSlidersQuery({});
  const SLIDERDURATION = 1000;
  return (
    <StyledBannerSlider>
      {isLoading && typeof data == 'undefined' ? (
        <Skeleton variant="rounded" width={'100%'} height={300} />
      ) : data?.sliders ? (
        <ReactImageGallery
          items={data?.sliders ?? []}
          showThumbnails={false}
          showFullscreenButton={false}
          isRTL={true}
          // disableSwipe={true}
          showPlayButton={false}
          showBullets
          autoPlay
          slideDuration={SLIDERDURATION}
          swipingTransitionDuration={3000}
        />
      ) : (
        <Box component="img" src={banner} width="100%" borderRadius={1} />
      )}
    </StyledBannerSlider>
  );
};
