import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IBreadcrumbs {
  pageName: string | undefined;
}

export const Breadcrumb = ({ pageName }: IBreadcrumbs) => {
  const navigate = useNavigate();
  return (
    <Box role="presentation">
      <Breadcrumbs aria-label="breadcrumb" color="text.primary">
        <Link underline="hover" color="text.primary" onClick={() => navigate('/')}>
          خانه
        </Link>
        {pageName ? (
          <Link underline="hover" color="text.primary" aria-current="page">
            <b>{pageName}</b>
          </Link>
        ) : null}
      </Breadcrumbs>
    </Box>
  );
};
