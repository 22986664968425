import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { IMeta } from '../../../redux';

interface ICPaginationProps {
  meta?: IMeta;
  handlechange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const CPagination: React.FC<ICPaginationProps> = ({ meta, handlechange }) => {
  if (meta) {
    return (
      <Stack spacing={2}>
        <Pagination
          count={meta?.last_page}
          page={meta?.current_page}
          variant="outlined"
          color="primary"
          onChange={handlechange}
        />
      </Stack>
    );
  } else {
    return null;
  }
};
