import { Box, Grid, IconButton, Skeleton, styled } from '@mui/material';

export const StyledImgProductDetails = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.common.white,
  borderRadius: theme.spacing(4),
  padding: theme.spacing(8),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 200,
  minHeight: 200,
  boxShadow: '0 0 50px rgb(0 0 0 / 0.2)',
  img: {
    width: 200,
    height: 200,
    objectFit: 'contain',
    borderRadius: theme.spacing(4),
  },
}));

export const StyledProductDetails = styled(Grid)(({ theme }) => ({
  '.MuiGrid-root.MuiGrid-container': {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
      display: 'flex',
    },
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid',
  borderRadius: '100%',
}));
export const StyledIconButtonsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
    minWidth: 200,
  },
}));

export const StyledProImgGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  },
}));

export const StyledRoundedRecSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  width: '100%',
  height: 50,
}));

export const StyledHeaderSkeleton = styled(Skeleton)(({ theme }) => ({
  marginTop: theme.spacing(4),
  height: 20,
  width: '30%',
}));

export const StyledParagraphSkeleton = styled(Skeleton)(({ theme }) => ({
  height: 15,
  width: '80%',
}));

export const StyleImgSkeleton = styled(Skeleton)(({ theme }) => ({
  height: 250,
  width: 250,
}));
