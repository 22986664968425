import * as React from 'react';
import { PropsWithChildren } from 'react';
import AppHeader from '../headers/AppMenu';
import { LayoutContainer, StyledMain } from './MainLayout.styles';
import AppNavigation from '../headers/AppNavigation';
import Footer from '../footers/Footer';
import AuthChecker from '../authChecker/AuthChecker';
import { getLocalStorageItem } from '../../utils/local-storage';
import UnAuthAppMenu from '../headers/UnAuthAppMenu';

const MainLayout = ({ children }: PropsWithChildren) => {
  const auth = getLocalStorageItem(`${window.location.host}-token`);
  return (
    <StyledMain>
      {/* in auth menu we need some api calls and save data in redux */}
      {/* todo: common components should be defined for Header & AppMenu */}
      <AuthChecker>
        <AppHeader />
      </AuthChecker>
      {!auth && <UnAuthAppMenu />}
      <LayoutContainer>{children}</LayoutContainer>
      <Footer />
      <AuthChecker>
        <AppNavigation />
      </AuthChecker>
    </StyledMain>
  );
};

export default MainLayout;
