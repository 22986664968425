//todo:after completed api we should rmv this file

export const MakeRulesData = (companyName?: string | null) => {
  return [
    {
      id: 1,
      title: 'معرفی',
      description: `"فروشگاه خانوار" یکی از مزایای حمایتی گروه ${companyName} است که بر اساس آن، اقلام اساسی و پرکاربرد برای عموم خانواده ها، بصورت عمده خریداری شده و با اعمال سوبسید شرکتی، با قیمتی کمتر از نرخ خرید، به همکاران فروخته می شود.
          بر این اساس، فروشگاه خانوار، نه تنها ماهیت انتفاعی (سودآوری) ندارد، بلکه با استفاده از:`,
      list: {
        style: 'disc',
        items: [
          { id: 1, description: 'تخفیف حاصل از خریده عمده کالاها (به نفع همکاران)،' },
          { id: 2, description: 'اعمال سوبسید شرکتی بر روی قیمت خرید کالاها،' },
          {
            id: 3,
            description: `و ثابت نگهداشتن قیمت کالاهای موجود در انبار (مقابله با تورم)،
            تلاش می کند تا کالاهای اساسی و پر‌مصرف خانوار را با قیمتی بسیار کمتر از نرخ بازار، به سفره همکاران رسانده و از این طریق، به معیشت ایشان کمک نماید.`,
          },
        ],
      },
    },
    {
      id: 2,
      title: 'دامنه استفاده',
      description: null,
      list: {
        style: 'disc',
        items: [
          {
            id: 1,
            description: `استفاده از فروشگاه خانوار، صرفا برای همکاران بخشهای تولیدی گروه ${companyName} که دارای کد پرسنلی فعال باشند، مجاز و میسر می‌باشد .`,
          },
          {
            id: 2,
            description:
              'با توجه به اینکه این مزیت، مختص کارکنان می‌باشد، درصورتیکه به هر علت ارتباط کاری قطع شده و کد پرسنلی فرد غیر فعال گردد، کد کاربری فروشگاه نیز غیر فعال شده و امکان خرید از فروشگاه وجود نخواهد داشت.',
          },
          {
            id: 3,
            description: `همکاران جدید الاستخدام نیز، از اولین روز کاری، امکان استفاده از این مزیت را دارا می باشند.`,
          },
        ],
      },
    },
    {
      id: 3,
      title: 'چگونگی استفاده',
      description: null,
      list: {
        style: 'disc',
        items: [
          {
            id: 1,
            description: `خرید از فروشگاه خانوار، کاملا اختیاری بوده و هیچگونه الزامی در این خصوص برای همکاران وجود ندارد.`,
          },
          {
            id: 2,
            description: `خرید از فروشگاه، صرفا بصورت الکترونیک و با استفاده از نرم افزاری که در اختیار مشمولان قرار گرفته است، بدون محدودیت زمانی صورت می گیرد.`,
          },
          {
            id: 3,
            description: `همانند سایر خریدهای اینترنتی، برای خرید از فروشگاه خانوار نیز استفاده از گوشی هوشمند و شماره موبایل مشخصی که بنام همکار در سیستم پرسنلی ثبت شده است، ضروری می‌باشد.`,
          },
          {
            id: 4,
            description: `برای بهره مندی عموم همکاران از این فرصت و توزیع منصفانه سوبسید شرکتی، شروط زیر در پلتفرم فروشگاه در نظر گرفته شده است:`,
            list: {
              style: 'decimal',
              items: [
                {
                  id: 1,
                  description: `محدودیت در سقف ریالی خرید (مبلغ کل سفارش)`,
                },
                {
                  id: 2,
                  description: `محدودیت در تعداد خرید هر یک از اقلام (مخصوصا کالاهای کمیاب و پر طرفدار)`,
                },
              ],
            },
          },
          {
            id: 4,
            description: `لاگین و پسورد هر یک از همکاران فقط مختص خود کاربر بوده و در اختیارگذاری آن ، برای هر دو همکار ،تخلف آگاهانه محسوب می‌شود.`,
          },
          {
            id: 5,
            description:
              'از آنجا که اجناس فروشگاه با سوبسید شرکتی عرضه شده و این سوبسید به ازای هر فرد تعیین شده است،استفاده از اکانت دیگران برای خریدهای شخصی،به منزله ی سوء استفاده از این مزیت تلقی شده و برای هر دو همکار تخلف آگاهانه محسوب می‌گردد.',
          },
          {
            id: 6,
            description:
              'در صورت مواجه با موارد تخلف و اثبات آنها،کد کاربری همکاران متخلف، بنابر تشخیص مسئولین فروشگاه ، به صورت موقت یا دائم مسدود خواهد شد.',
          },
        ],
      },
    },
    {
      id: 4,
      title: 'نحوه درخواست و تحویل سفارشات',
      description:
        'خرید از فروشگاه، شامل مراحل زیر می‌باشد و در هر مرحله پیامک مخصوصی برای اطلاع رسانی به متقاضی ارسال می گردد:',
      list: {
        style: 'disc',
        items: [
          {
            id: 1,
            description: `وضعیت در انتظار پرداخت : ثبت درخواست و تایید جمع کل فاکتور توسط متقاضی`,
          },
          {
            id: 2,
            description: `وضعیت در انتظار تایید پرداخت : واریز جمع مبلغ فاکتور توسط متقاضی`,
          },
          {
            id: 3,
            description: `وضعیت در انتظار بسته بندی : انجام کنترل های لازم و تایید سفارش توسط ادمین`,
          },
          {
            id: 4,
            description: `وضعیت آماده تحویل : بسته بندی کالاها بهمراه فاکتور فروش، توسط مسئول فروشگاه`,
          },
          {
            id: 5,
            description: `وضعیت تحویل داده شد : دریافت حضوری سفارشات در بسته های پلمب شده`,
            list: {
              style: 'decimal',
              items: [
                {
                  id: 1,
                  description: `تحویل سفارشات فقط در پایان شیفت های کاری و در هنگام خروج همکاران میسر خواهد بود.`,
                },
                {
                  id: 2,
                  description: `برای دریافت سفارشات، اعلام "شماره سفارش" الزامی است.`,
                },
                {
                  id: 3,
                  description: `مسئولیت کنترل بسته ها و کالاها در هنگام تحویل، بر عهده متقاضی می‌باشد.`,
                },
                {
                  id: 4,
                  description: `دریافت بسته ها و خروج از فروشگاه به منزله تحویل کامل و سالم سفارش تلقی می گردد.`,
                },
                {
                  id: 5,
                  description: `کالاهای خریداری شده از فروشگاه، فقط درصورتیکه پلمب بسته ها باز نشده باشند، امکان خروچ از شرکت را خواهند داشت.`,
                },
              ],
            },
          },
        ],
      },
    },
    {
      id: 5,
      title: `ضوابط لغو سفارش، مرجوعی و تعویض کالا`,
      description: null,
      list: {
        style: 'disc',
        items: [
          {
            id: 1,
            description: `تا زمانیکه پرداخت وجه سفارش انجام نشده باشد، سفارش "نهایی" تلقی نخواهد شد و امکان لغو آن توسط متقاضی وجود دارد.`,
          },
          {
            id: 2,
            description: `بعد از پرداخت وجه (نهایی شدن سفارش)، امکان ابطال درخواست، تغییر کالاهای انتخابی و یا مرجوع شدن وجه پرداختی توسط متقاضی وجود نخواهد داشت.`,
          },
          {
            id: 3,
            description: `در هنگام تحویل سفارش، در صورت وجود هر گونه اشکال در اجناس و یا اشتباه در اقلام بسته بندی شده، امکان تعویض وجود خواهد داشت و نسبت به پلمب مجدد بسته دارای اشکال، اقدام می گردد.`,
          },
          {
            id: 4,
            description: `با توجه به حساسیت اقلام فروشگاه و رعایت اصول بهداشتی مواد خوراکی، بعد از تحویل بسته های سفارش و خروج متقاضی از فروشگاه، در صورتیکه پلمب بسته ها باز نشده باشد، تا 24 ساعت امکان تعویض کالاهایی که احتمالا دچار آسیب یا اشکال باشند، وجود خواهد داشت.`,
          },
        ],
      },
    },
  ];
};
