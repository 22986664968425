import { Box, styled } from '@mui/material';

export const gradientBg: string =
  'linear-gradient(90deg, rgba(255,0,0,0.8631827731092436) 0%, rgba(255,97,30,0.8547794117647058) 50%, rgba(255,0,0,1) 100%)';

export const StyledLogin = styled(Box)(({ theme }) => ({
  '.MuiGrid-item': {
    [theme.breakpoints.down('md')]: {
      borderRadius: theme.shape.borderRadius,
    },
    '&:nth-of-type(2n)': {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    '&:nth-of-type(1)': {
      p: {
        textAlign: 'justify',
        padding: theme.spacing(2, 0),
        fontWeight: 'bold',
      },
    },
  },
  img: {
    [theme.breakpoints.down('md')]: {
      width: 120,
    },
  },
}));
