import * as React from 'react';
import Card from '@mui/material/Card';
import { Box, Skeleton } from '@mui/material';
import { MAINTOP, PRODUCTWIDTH, StyledProductCard } from './ProductCard.styles';

export const ProductCardSkeleton = () => {
  return (
    <StyledProductCard>
      <Card sx={{ padding: `${MAINTOP} 2px 0`, alignItems: 'center' }}>
        <Box>
          <Skeleton
            animation="wave"
            variant="circular"
            width={PRODUCTWIDTH}
            height={PRODUCTWIDTH}
          />
        </Box>
        <Skeleton width={'80%'} height={30} sx={{ padding: 6 }} />
        <Skeleton animation="wave" height={15} width="80%" />
        <Skeleton animation="wave" height={15} width="80%" />
      </Card>
    </StyledProductCard>
  );
};
