let PERSIANNUMBER = [/۰/gi, /۱/gi, /۲/gi, /۳/gi, /۴/gi, /۵/gi, /۶/gi, /۷/gi, /۸/gi, /۹/gi];

export function OrderState(state: string) {
  switch (state) {
    case 'pending':
      return 'منتظر پرداخت';
    case 'cancel':
      return 'لغو شده';
    case 'complete':
      return 'منتظر بسته بندی';
    case 'waiting_confirmation':
      return 'منتظر تایید پرداخت';
    case 'delivered':
      return 'تحویل شد';
    case 'ready_to_send':
      return 'آماده ارسال/تحویل';
    default:
      return state;
  }
}
export function toFarsiNumber(n: string) {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  return n.toString().replace(/\d/g, (x) => farsiDigits[Number(x)]);
}
export function toEnglishNumber(str: string) {
  for (let i = 0; i < 10; i++) {
    str = str.replace(PERSIANNUMBER[i], String(i));
  }
  return str;
}
