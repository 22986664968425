import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { getLocalStorageItem } from '../utils/local-storage';
import { useEffect } from 'react';
import MainLayout from '../components/layouts/MainLayout';

export const AppRoutes = () => {
  const auth = getLocalStorageItem(`${window.location.host}-token`);
  const navigate = useNavigate();
  const location = useLocation();
  const routes = auth ? protectedRoutes : publicRoutes;
  const element = useRoutes(routes);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  useEffect(() => {
    if (!auth && location.pathname === '/') navigate('/login');
  }, [auth, location.pathname]);

  return location.pathname === '/login' ? element : <MainLayout>{element}</MainLayout>;
};
