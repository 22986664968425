import React from 'react';
import { Typography, styled } from '@mui/material';
import Box from '@mui/system/Box';
import notProduct from '../../../assets/img/noProduct.jpeg';

const BoxNotFoundProduct = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  with: '100%',
  height: '50vh',
  margin: theme.spacing(0, 'auto'),
  img: {
    maxWidth: 200,
    borderRadius: '100%',
    width: '100%',
    margin: theme.spacing(0, 'auto', 5),
  },
}));

function NotFoundProduct() {
  return (
    <BoxNotFoundProduct>
      <img src={notProduct} alt="no-product" />
      <Typography color="error" fontWeight={'bold'}>
        محصولی یافت نشد!
      </Typography>
    </BoxNotFoundProduct>
  );
}

export default NotFoundProduct;
