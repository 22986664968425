import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { LoadingProvider, LoadingLinearProgress } from '../components/loading/LoadingProvider';
import { familyShopThemeOptions } from '../theme/familyShopThemeOptions';
import MaterialProvider from './material-provider/provider/MaterialProvider';
import { store } from '../redux';
import SnackBar from '../components/snackbar/SnackBar';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ReduxProvider store={store}>
      <MaterialProvider
        theme={(themeOptions) => ({
          ...themeOptions,
          ...familyShopThemeOptions,
        })}
      >
        <React.Suspense fallback={<LoadingLinearProgress />}>
          <LoadingProvider>
            <SnackBar>
              <Router>{children}</Router>
            </SnackBar>
          </LoadingProvider>
        </React.Suspense>
      </MaterialProvider>
    </ReduxProvider>
  );
};
