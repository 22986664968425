import React from 'react';
import { ICategory } from '../../../redux';
import { Box, Grid, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface ICategoryMenuItems {
  item: ICategory;
  onSelect: (item: ICategory) => void;
}

const SubCategoryItem = ({ item, onSelect }: ICategoryMenuItems) => {
  if (typeof item === 'undefined') return null;
  return (
    <Grid item xl={4} lg={4} md={6} onClick={() => onSelect(item)}>
      <Box key={item.id} className="link" mb={4} mt={2} px={5}>
        <Typography variant="body2">{item.name}</Typography>
        <KeyboardArrowLeftIcon />
      </Box>
    </Grid>
  );
};
export default SubCategoryItem;
