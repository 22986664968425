import Login from '../pages/Login/Login';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import InfoIcon from '@mui/icons-material/Info';
import AboutUs from '../pages/AboutUs/AboutUs';
import Faq from '../pages/Faq/Faq';
import Rules from '../pages/Rules/Rules';
import ContactUs from '../pages/ContactUs/ContactUs';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import GavelIcon from '@mui/icons-material/Gavel';
import { PublicRoute } from './types';
import Page404 from '../pages/404/Page404';

export const publicRoutes: PublicRoute[] = [
  {
    path: '*',
    element: <Page404 />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/faq',
    element: <Faq />,
    name: 'راهنمای خرید',
    icon: <HelpCenterIcon />,
  },
  {
    path: '/about-us',
    element: <AboutUs />,
    name: 'درباره ما',
    icon: <InfoIcon />,
  },

  {
    path: '/rules',
    element: <Rules />,
    icon: <GavelIcon />,
    name: 'قوانین و مقررات',
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
    name: 'تماس با ما',
    icon: <PhoneEnabledIcon />,
  },
];
