import { PayloadAction } from '@reduxjs/toolkit';
import { createGenericSlice, GenericState } from '../GenericSlice';
import { IProduct, IShopCart } from '../rtkQuery/api-types';

export interface IShopPayload {
  cart_id?: number | string;
  product_id: number;
  quantity: number;
  shopCart?: IShopCart[];
}
export interface IShopPayloadAdd {
  cart_id?: string | number | undefined;
  product_id: number;
  quantity: number;
  shopCart?: IShopCart[];
  item?: IProduct;
}

const slice = createGenericSlice({
  name: 'shopCart',
  initialState: {
    status: 'loading', //Todo:we didn`t use this property anywhere
    data: {},
  } as GenericState<IShopCart[]>,
  reducers: {
    setShop: (state, action: PayloadAction<IShopCart[]>) => {
      state.data = action.payload;
    },
    addShop: (state, action: PayloadAction<IShopPayloadAdd>) => {
      if (typeof state.data !== 'undefined') {
        const cart = state.data.find((pro) => pro.product_id === action.payload.product_id);
        if (cart) {
          // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
          cart.quantity = action.payload.quantity;
        } else {
          if (action.payload.item && typeof action.payload.cart_id !== 'undefined') {
            state.data.push({
              id: Number(action.payload.cart_id),
              product_id: action.payload.item.id,
              quantity: 1,
              product_data: action.payload.item,
            });
          }
        }
      }
    },
    deleteShop: (state, action: PayloadAction<IShopPayload>) => {
      if (typeof state.data !== 'undefined') {
        const cart = state.data.find((product) => product.id === action.payload.cart_id);
        const cartIndex = state.data.findIndex((cart) => cart.id === action.payload.cart_id);
        if (cart) {
          //if click on recycleBin in cartShopList Product should be delete from basket
          if (Number(action.payload.quantity) === 0) {
            state.data.splice(cartIndex, 1);
          } else {
            // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
            cart.quantity = action.payload.quantity;
          }
        }
      }
    },
  },
});

export const { setShop, addShop, deleteShop } = slice.actions;
export const selectTotalQuantityShopCart = (state: { shopCart: { data: IShopCart[] } }) => {
  let totalQuantity: number = 0;
  if (state?.shopCart?.data?.length > 0) {
    state?.shopCart?.data?.map((shop) => {
      totalQuantity += Number(shop.quantity);
    });
  }
  return totalQuantity;
};
export const selectTotalPriceShopCart = (state: { shopCart: { data: IShopCart[] } }) => {
  let totalPrice: number = 0;
  if (state?.shopCart?.data?.length > 0) {
    state?.shopCart?.data?.map((shop) => {
      totalPrice += Number(shop?.product_data?.product_price) * Number(shop?.quantity);
    });
  }
  return totalPrice;
};
export const selectTotalDiscountedPriceShopCart = (state: { shopCart: { data: IShopCart[] } }) => {
  let totalDiscountedPrice: number = 0;
  if (state?.shopCart?.data?.length > 0) {
    state?.shopCart?.data?.map((shop) => {
      totalDiscountedPrice += Number(shop?.product_data?.sale_price) * Number(shop?.quantity);
    });
  }
  return totalDiscountedPrice;
};
export const selectTotalProfitShopCart = (state: { shopCart: { data: IShopCart[] } }) => {
  let totalProfit: number = 0;
  if (state?.shopCart?.data?.length > 0) {
    state?.shopCart?.data?.map((shop) => {
      totalProfit +=
        (Number(shop?.product_data?.product_price) - Number(shop?.product_data?.sale_price)) *
        Number(shop?.quantity);
    });
  }
  return totalProfit;
};

export default slice.reducer;
