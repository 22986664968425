import { Box, Typography } from '@mui/material';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useGetSlidersMessageQuery } from '../../../redux';
import { StyledMessageSlider } from './BannerSlider.styles';

export const MessageSlider = () => {
  const { data, isLoading } = useGetSlidersMessageQuery({});
  function renderItem(item: ReactImageGalleryItem) {
    return (
      <Box className="image-gallery-image">
        <Box className="image-gallery-description">
          <Typography color={'theme.primary'} variant="h6">
            {item?.description}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <>
      {!isLoading ? (
        <StyledMessageSlider>
          <ReactImageGallery
            items={data?.sliders ?? []}
            showThumbnails={false}
            showFullscreenButton={false}
            isRTL={true}
            showPlayButton={false}
            renderItem={renderItem}
            autoPlay={true}
          />
        </StyledMessageSlider>
      ) : null}
    </>
  );
};
