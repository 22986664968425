import { AdvertisingBanners } from '../AdvertisingBanners';
import unlimitedIcon from '../../../../assets/img/unlimitedIcon.png';
import unlimitedTxt from '../../../../assets/img/unlimitedTxt.png';
import unlimitedTxtBox from '../../../../assets/img/unlimitedTxtBox.png';
import { useNavigate } from 'react-router-dom';
import { IBannersBox } from '../BannersContainer';
import { useAppSelector } from '../../../../redux';

export const KalaPlusBanner = ({ countOfBanners }: IBannersBox) => {
  const categories = useAppSelector((state) => state.categories.data);
  const navigate = useNavigate();
  return (
    <AdvertisingBanners
      background="linear-gradient(90deg, rgba(247,60,36,1) 0%, rgba(242,101,34,1) 50%, rgba(247,60,36,1) 100%)"
      iconSrc={unlimitedIcon}
      txtImgSrc={unlimitedTxt}
      txtBoxImgSrc={unlimitedTxtBox}
      onclick={() => navigate(`/categories/${categories?.meta?.tags_id?.kala_plus}`)}
      countOfBanners={countOfBanners}
    />
  );
};
