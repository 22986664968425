import { Box, Grid, styled } from '@mui/material';
import Modal from '@mui/material/Modal';

export const ModalContainer = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
export const ButtonContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  button: {
    width: '100%',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
}));
export const CModalStyle = styled(Box)(({ theme, ...props }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 500,
  minHeight: 250,
  transform: 'translate(-50%, -50%)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[10],
  padding: theme.spacing(5),
  background: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    padding: theme.spacing(5, 3),
  },
}));
