// Need to use the React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getLocalStorageItem } from '../../utils/local-storage';
import { enqueueSnackbar } from 'notistack';
import { REHYDRATE } from 'redux-persist';
import { IError } from './api-types';

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    cache: 'default',
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const token = getLocalStorageItem(`${window.location.host}-token`);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Accept', `application/json`);
        headers.set('Access-Control-Allow-Origin', '*');
      }
      return headers;
    },
    responseHandler: async (response) => {
      if (response.status === 400) {
        response?.json()?.then((errors) => {
          errors?.map((err: IError) => {
            return enqueueSnackbar(err?.data?.message, {
              variant: 'error',
            });
          });
        });
      }
      if (response.status === 401) {
        window.location.replace('/login');
      }
      if (response.status === 403) {
        window.location.replace('/403-forbidden');
      }
      if (response.status === 404) {
        //TODO: show error message for createFaq API we dont have message & correct response
        return enqueueSnackbar('مشکلی پیش آمده است . لطفا مجددا تلاش کنید.', {
          variant: 'error',
        });
      }
      if (response.status === 422) {
        response.json().then((error) => {
          enqueueSnackbar(Object.values(error?.errors).flat().join(', '), {
            variant: 'error',
          });
        });
      }
      return response.json();
    },
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    // when persisting the root reducer
    if (action.type === REHYDRATE) {
      return action.payload[reducerPath];
    }

    // when persisting the api reducer
    if (action.type === REHYDRATE && action.key === 'key used with redux-persist') {
      return action.payload;
    }
  },
  tagTypes: ['orders', 'products', 'shopCart', 'profile', 'categories'],
  endpoints: () => ({}),
});
