import { useSnackbar } from 'notistack';
import { IError } from '../redux';

export const useFormErrors = () => {
  const { enqueueSnackbar } = useSnackbar();
  const setFormErrors = (e: IError) => {
    e?.data?.errors && Object.entries(e?.data?.errors).forEach(([key, msg]) => {
      let msgTxt= msg.map((m: string) => m).join('\n')
      enqueueSnackbar(msgTxt, {
        variant: 'error',
      });
    }, []);
    e?.data?.message && !e?.data?.errors && enqueueSnackbar(e?.data?.message, {
      variant: 'error',
    });
  };

  return { setFormErrors };
};
