import { Box } from '@mui/material';
import { StyledGridOverlay } from './NoRows.styles';
import { NoFileSvg } from './NoFileSvg';

export default function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <NoFileSvg />

      <Box sx={{ mt: 1 }}>ردیفی موجود نیست</Box>
    </StyledGridOverlay>
  );
}
