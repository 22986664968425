import { LinearProgress } from '@mui/material';
import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

interface LoadingContextValue {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const LoadingContext = createContext<LoadingContextValue>({
  isLoading: false,
  setIsLoading: () => {},
});

export const LoadingProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading ? <LoadingLinearProgress /> : null}
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);

export const LoadingLinearProgress = () => {
  return (
    <LinearProgress
      sx={{
        position: 'absolute',
        width: '100%',
        top: 0,
        zIndex: 9999,
      }}
    />
  );
};
