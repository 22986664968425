import { MaterialDesignContent, VariantType } from 'notistack';
import { styled } from '@mui/material';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme, variant }) => ({
  borderRadius: theme.spacing(8),
  backgroundColor: theme.palette.background.default + 'f0',
  color:
    variant === 'success'
      ? theme.palette.success.main
      : variant === 'error'
      ? theme.palette.error.main
      : variant === 'warning'
      ? theme.palette.warning.main
      : variant === 'info'
      ? theme.palette.info.main
      : theme.palette.text.primary,
}));

export const NotistackComponents: {
  [variant in VariantType]?: React.JSXElementConstructor<any>;
} = {
  success: StyledMaterialDesignContent,
  error: StyledMaterialDesignContent,
  default: StyledMaterialDesignContent,
  warning: StyledMaterialDesignContent,
  info: StyledMaterialDesignContent,
};
