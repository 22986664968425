import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/system/Box';
import notMatchImg from './../../assets/img/notMatch.png';
import { useNavigate } from 'react-router-dom';
import { CButton } from '../../components/ui-components';
import { getLocalStorageItem } from '../../utils/local-storage';

function Page404() {
  const navigate = useNavigate();
  const auth = getLocalStorageItem(`${window.location.host}-token`);
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '60vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={notMatchImg}
        style={{
          maxWidth: 500,
          width: '100%',
        }}
        alt="image404"
      />
      <Typography variant="h5" color="error" mb={4}>
        صفحه ای که بدنبال آن می‌گردید نامعتبر است.
      </Typography>
      <CButton
        color="info"
        variant="outlined"
        onClick={() => navigate(auth ? '/' : '/login')}
        size="small"
      >
        بازگشت به صفحه اصلی
      </CButton>
    </Box>
  );
}

export default Page404;
