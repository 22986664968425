import { PayloadAction } from '@reduxjs/toolkit';
import { createGenericSlice, GenericState } from '../GenericSlice';
import { ICategoryRes } from '../rtkQuery/api-types';

const slice = createGenericSlice({
  name: 'categories',
  initialState: {
    status: 'loading',
    data: {},
  } as GenericState<ICategoryRes>,
  reducers: {
    setCategories: (state, action: PayloadAction<ICategoryRes>) => {
      state.data = action.payload;
    },
  },
});

export const { setCategories } = slice.actions;

export default slice.reducer;
