import { inputLabelClasses, outlinedInputClasses, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CTextFieldRootStyle = styled(TextField)(({ theme, ...props }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    flexDirection: theme.direction === 'rtl' && props.dir === 'ltr' ? 'row-reverse' : undefined,
  },
  [`& fieldset.${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.secondary.light,
  },
  [`& .${inputLabelClasses.root}`]: {
    color: theme.palette.secondary.light,
  },
}));

export const CHelperTextWrapperStyle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const CHelperTextStyle = styled('span')(({ theme }) => ({
  margin: theme.spacing(1),
}));
