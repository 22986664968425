import { Box, Typography } from '@mui/material';
import { StyledRoundedImg } from '../Products/components/RoundedImg.styles';
import LazyImg from '../../components/loading/LazyImg';
import aboutUsBanner from '../../assets/img/aboutUseBanner.jpg';
import PlaceholderImg from '../../assets/img/lazyBanners.png';
import { useAppSelector } from '../../redux';

const AboutUs = () => {
  const company = useAppSelector((state) => state.companyInfo.data);
  return (
    <>
      <Box p={2}>
        <StyledRoundedImg>
          <LazyImg src={aboutUsBanner} alt={'about-us'} PlaceholderImg={PlaceholderImg} />
        </StyledRoundedImg>
        <Typography my={5} lineHeight={'2.1rem'} textAlign={'justify'}>
          فروشگاه خانوار، بعنوان یکی از مزایای گروه {company?.name} برای پشتیبانی از معیشت همکاران
          خود در سال 1402 افتتاح گردید. این فروشگاه نه تنها ماهیت سودآور و انتفاعی ندارد، بلکه با
          تخفیفی که از طریق خرید عمده کالاها بدست می آورد و همچنین سوبسید شرکتی که بر نرخ خرید اعمال
          می نماید، تمامی کالاهای خود را با قیمتی به مراتب ارزانتر از نرخ بازار به همکاران خود عرضه
          می نماید. محور فعالیت این فروشگاه صرفا "کالاهای اساسی و پر‌مصرف" است که مورد استفاده عموم
          خانواده ها می‌باشد. بنابراین همچون سایر فروشگاههای اینترنتی، تنوع صرف در اقلام و برندها و
          گسترش سبد کالاها، جزو سیاستهای آن نمی‌باشد و همانگونه که بیان شد، صرفا محدود به فروش
          کالاهای اساسی و پر‌مصرف می‌باشد.
        </Typography>
      </Box>
    </>
  );
};
export default AboutUs;
