import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { protectedRoutes } from '../../routes/protected';
import { StyledAppNavigation, StyledBadge } from './AppNavigation.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTotalQuantityShopCart } from '../../redux';
import { toFarsiNumber } from '../../utils/helper';

const AppNavigation = () => {
  const [value, setValue] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const shopCount = useSelector(selectTotalQuantityShopCart);
  useEffect(() => {
    if (location.pathname) {
      let currentRoute = protectedRoutes.find((route) => route.path === location.pathname);
      if (currentRoute) {
        setValue(currentRoute.id - 1);
      }
    }
  }, [location]);

  return (
    <StyledAppNavigation>
      <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation showLabels value={value}>
            {protectedRoutes.length > 0
              ? protectedRoutes.map((route, i) => {
                  return (
                    !route.hidden && (
                      <BottomNavigationAction
                        key={route.id}
                        label={route.name}
                        icon={
                          route.path === '/shop-cart' && shopCount > 0 ? (
                            <StyledBadge
                              badgeContent={toFarsiNumber(String(shopCount))}
                              color="secondary"
                            >
                              {route.icon}
                            </StyledBadge>
                          ) : (
                            route.icon
                          )
                        }
                        onClick={() => navigate(route.path)}
                      />
                    )
                  );
                })
              : null}
          </BottomNavigation>
        </Paper>
      </Box>
    </StyledAppNavigation>
  );
};

export default AppNavigation;
