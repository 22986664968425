import { Box, styled } from '@mui/material';

export const StyledMain = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  flexGrow: 1,
  height: '100vh',
  position: 'relative',
}));

export const LayoutContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1200,
  margin: theme.spacing(1, 'auto'),
  padding: theme.spacing(5, 4),
  [theme.breakpoints.up('sm')]: {
    minHeight: '78vh',
  },
}));
