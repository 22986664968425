import { ComponentPropsWithoutRef, useState } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';
import { GridProps, Grid, Box } from '@mui/material';
import { CTextField } from '../CTextfield/CTextField';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { ImageUploadBox } from './CInputFile.styles';
import { enqueueSnackbar } from 'notistack';

interface IProps<T extends FieldValues> {
  inputProps?: ComponentPropsWithoutRef<typeof CTextField>;
  formProvider: UseFormReturn<T, any>;
  name: Path<T>;
  control: Control<T>;
  defaultValue?: any;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
      >
    | undefined;
  gridProps?: GridProps;
}

export const CFileInput = <T extends FieldValues>({
  inputProps,
  formProvider,
  name,
  defaultValue,
  control,
  rules,
  gridProps,
}: IProps<T>) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>();

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<T, Path<T>>
  ) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewUrl(reader.result as string);
      };
      enqueueSnackbar('برای ثبت نهایی رسید خرید حتما بر روی دکمه ارسال کلیک کنید.', {
        variant: 'warning',
      });
    } else {
      setPreviewUrl(null);
    }
    field.onChange(file as PathValue<T, Path<T>>);
  };

  return (
    <Grid item {...gridProps}>
      <Controller<T>
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <Grid container justifyContent="flex-start">
            <Grid
              item
              xs={12}
              md={12}
              mb={4}
              container
              justifyContent="center"
              position={'relative'}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={(theme) => theme.palette.grey[300]}
                sx={(theme) => ({
                  borderRadius: theme.spacing(5),
                  width: '100%',
                  height: 250,
                })}
              >
                {previewUrl ? (
                  <Box
                    component="img"
                    src={previewUrl}
                    alt="Preview"
                    bgcolor={(theme) => theme.palette.grey[300]}
                    sx={(theme) => ({ height: 200, borderRadius: theme.spacing(5) })}
                  />
                ) : (
                  <AddAPhotoIcon
                    sx={(theme) => ({
                      fontSize: '5rem',
                      color: theme.palette.grey[100],
                    })}
                  />
                )}
              </Box>
              <ImageUploadBox>
                <CTextField
                  {...field}
                  {...inputProps}
                  value={null}
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: any) => handleFileChange(e, field)}
                  helperText={formProvider.formState.errors?.message as any}
                  error={!!formProvider.formState.errors.name}
                  sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: 200 }}
                />
              </ImageUploadBox>
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
};
