import { Box, Card, Grid, Typography, styled } from '@mui/material';

const PADDINGـPROـIMG: number = 10;
export const MAINTOP: number = 10;
export const PRODUCTWIDTH: number = 120;

export const StyledProductCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'unset',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: MAINTOP,
  padding: theme.spacing(1.5),
  background: theme.palette.common.white,
  cursor: 'pointer',
  '.MuiCard-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 2),
    '.MuiCardContent-root': {
      padding: theme.spacing(1),
    },
    '.MuiBox-root': {
      objectFit: 'contain',
      backgroundSize: 'contain',
      display: 'flex',
      justifyContent: 'center',
      padding: PADDINGـPROـIMG,
      'img.MuiCardMedia-root': {
        height: PRODUCTWIDTH,
        objectFit: 'contain',
        backgroundSize: 'contain',
      },
    },
  },
}));

export const ProColumn = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(360)]: {
    flexBasis: '100%',
    flexGrow: 0,
    maxWidth: '100%',
    margin: theme.spacing(2),
  },
}));
export const StyledLineThroughTypography = styled(Typography)(({ theme }) => ({
  textDecoration: 'line-through',
  color: theme.palette.grey[500],
}));

export const StyledQuantityBox = styled('span')(({ theme }) => ({
  borderRadius: '100%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0, 1.3),
  fontSize: '.8rem',
}));

export const TopImgIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -11,
  right: -22,
  borderRadius: theme.shape.borderRadius,
}));
