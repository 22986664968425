import { Box, styled } from '@mui/material';

export const StyledCollapseFilter = styled(Box)(({ theme }) => ({
  '.MuiListItemButton-gutters': {
    paddingTop: 0,
    paddingBottom: theme.spacing(0.5),
  },
  '.MuiTypography-root': {
    fontSize: '0.9rem',
  },
  '.MuiListItemIcon-root': {
    transform: 'rotate(180deg)',
    minWidth: 'unset',
    paddingLeft: theme.spacing(3),
  },
}));
