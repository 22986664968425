import { ThemeOptions } from '@mui/material';
import { familyShopPalette, secondary } from './familyShopPalette';

const components: ThemeOptions['components'] = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
      sx: {
        boxShadow: `0px 15px 30px 0px ${secondary.lighter}60`,
      },
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      color: 'secondary',
    },
  },
};

export const familyShopThemeOptions: ThemeOptions = {
  palette: familyShopPalette,
  direction: 'rtl',
  spacing: 4,
  shape: {
    borderRadius: 15,
  },
  components,
};
