import { Grid, Box, Typography } from '@mui/material';
import { useGetCategoriesQuery, useGetProductsQuery } from '../../redux';
import { ProductCard } from '../Products/components/ProductCard';
import React, { useEffect, useState } from 'react';
import { useLoading } from '../../components/loading';
import { ProductCardSkeleton } from '../Products/components/ProductCardSkeleton';
import { ProColumn } from '../Products/components/ProductCard.styles';
import { CPagination } from '../../components/ui-components/CPagination/CPagination';
import { useParams } from 'react-router';
import CollapseFilter from './components/CollapseFilter';
import NotFoundProduct from '../Products/components/NotFoundProduct';

const Categories = () => {
  const { setIsLoading } = useLoading();
  const PERPAGE: number = 18;
  const params = useParams<string>();
  const [page, setPage] = useState(1);
  const [activeCat, setActiveCat] = useState<number>(NaN);
  const { data: categories } = useGetCategoriesQuery({});
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const { data: products, isLoading } = useGetProductsQuery(
    {
      per_page: PERPAGE,
      search: {
        searchFields: 'category_id',
        searchValue: String(activeCat),
      },
      pagination: {
        page: page,
      },
    },
    { skip: !activeCat }
  );

  useEffect(() => {
    if (isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (params?.id !== ':id') {
      setActiveCat(Number(params.id));
    } else {
      if (typeof categories?.data !== 'undefined' && categories?.data?.length > 0) {
        if (
          categories?.data[0]?.sub_categories &&
          categories?.data[0]?.sub_categories?.length > 0
        ) {
          setActiveCat(Number(categories?.data[0]?.sub_categories[0].id));
        }
      }
    }
  }, [params?.id]);
  const onSelectCat = (id: number) => {
    setActiveCat(id);
  };
  return (
    <>
      <Grid container spacing={2} pt={{ xl: 5, lg: 5 }}>
        <Grid item xs={12}>
          <Typography px={3} fontWeight={'bold'}>
            دسته بندی کالاها
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={4} xs={12} pt={1} mt={{ sm: 5 }}>
          <CollapseFilter active={activeCat} onChange={onSelectCat} data={categories?.data} />
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
          <Grid container spacing={2}>
            {products?.data ? (
              products?.data?.length > 0 ? (
                products?.data?.map((item, i) => {
                  return (
                    <ProColumn key={i} item xl={3} lg={3} md={6} sm={6} xs={6}>
                      <ProductCard item={item} />
                    </ProColumn>
                  );
                })
              ) : (
                <NotFoundProduct />
              )
            ) : (
              <ProColumn item xs={6} sm={6} md={4} lg={3} xl={3}>
                <ProductCardSkeleton />
              </ProColumn>
            )}
          </Grid>
          <Box
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            pt={4}
            pb={10}
            sx={{
              width: '100%',
            }}
          >
            {products?.meta?.last_page !== 1 ? (
              <CPagination meta={products?.meta} handlechange={handleChange} />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Categories;
