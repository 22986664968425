import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ButtonContainer, CModalStyle, ModalContainer } from './CModal.styles';
import { TModal } from '../../pages/Login/Login';

type TCModal = {
  modal: TModal;
  handleModal: (show: boolean) => void;
};
const CModal = ({ modal, handleModal }: TCModal) => {
  return (
    <ModalContainer
      open={modal.show}
      onClose={() => handleModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CModalStyle>
        {modal?.title && (
          <Typography id="modal-modal-title" variant="body1" fontWeight="bold">
            {modal?.title}
          </Typography>
        )}
        {modal?.text && (
          <Typography id="modal-modal-description" variant="body1">
            {modal?.text}
          </Typography>
        )}

        <ButtonContainer>
          {modal?.item?.url && (
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                typeof modal?.item?.url !== 'undefined' &&
                  window.location.replace(modal?.item?.url);
              }}
            >
              ورود به سایت
            </Button>
          )}
          <Button variant="contained" color="warning" onClick={() => handleModal(false)}>
            بستن
          </Button>
        </ButtonContainer>
      </CModalStyle>
    </ModalContainer>
  );
};
export default CModal;
