import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFooter = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: theme.shadows[11],
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 60),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 5),
    },
  },
  a: {
    [theme.breakpoints.down(420)]: {
      margin: theme.spacing(10, 1, 6),
    },
    [theme.breakpoints.down(375)]: {
      margin: theme.spacing(2),
    },
  },
}));
export const FooterLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  a: {
    margin: theme.spacing(10, 3, 6),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.common.black,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.info.main,
    },
  },

  '&.login-footer': {
    [theme.breakpoints.down(375)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3, 0),
    },
    a: {
      [theme.breakpoints.down(420)]: {
        margin: theme.spacing(10, 1, 6),
      },
      [theme.breakpoints.down(375)]: {
        margin: theme.spacing(2),
      },
    },
  },
}));

export const StyledEnamad = styled(Box)(({ theme }) => ({
  width: 200,
  minHeight: 100,
  img: {
    width: 190,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  a: {
    margin: theme.spacing(0, 'auto'),
  },
}));
