import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
type ILazyImg = {
  alt?: string;
  height?: number;
  src: string;
  width?: number;
  PlaceholderImg: string;
};

const LazyImg = ({ alt, height, src, width, PlaceholderImg }: ILazyImg) => (
  <LazyLoadImage
    style={{ minHeight: height, objectPosition: 'center right', objectFit: 'cover' }}
    alt={alt}
    height={height}
    src={src}
    width={width ?? '100%'}
    effect="blur"
    placeholderSrc={PlaceholderImg}
    loading="lazy"
  />
);

export default LazyImg;
