import { Box, TableRow, styled, TableContainer } from '@mui/material';

export const StyledCartIconButton = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.secondary.light}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  padding: theme.spacing(1, 2),
  maxWidth: 150,
  '.Mui-disabled': {
    cursor: 'not-allowed',
    svg: {
      color: theme.palette.secondary.light,
    },
  },
}));
export const StyledPriceRow = styled(TableRow)(({ theme }) => ({
  width: '100%',
  '.MuiTableCell-root': {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.success.light,
  },
}));
export const StyledSubmitShopCart = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    padding: theme.spacing(6, 0),
    minWidth: 250,
    fontSize: '1rem',
  },
}));
export const StyledLabel = styled('span')(({ theme }) => ({
  display: 'none',
  fontWeight: 'bold',
  margin: theme.spacing(0, 0, 1, 0),
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const ResponsiveTable = styled(TableContainer)(({ theme }) => ({
  textShadow: theme.shadows[10],
  td: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      margin: theme.spacing(0, 'auto'),
    },
  },
  thead: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    th: {
      borderBottom: '0!important',
    },
  },
  a: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '.MuiBox-root': {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
}));
