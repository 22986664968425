import { Grid } from '@mui/material';
import { ImageBox } from './ImgBox';

type TFullScreenAdvertisingBanner = {
  background: string;
  iconSrc: string;
  txtImgSrc: string;
  txtBoxImgSrc: string;
  onclick: () => void;
};
export const FullScreenAdvertisingBanner = ({
  background,
  iconSrc,
  txtImgSrc,
  txtBoxImgSrc,
  onclick,
}: TFullScreenAdvertisingBanner) => {
  return (
    <Grid
      container
      sx={{
        width: '100%',
        borderRadius: 1,
        cursor: 'pointer',
        minHeight: 100,
      }}
      my={3}
      onClick={onclick}
      style={{
        background: background,
      }}
    >
      <ImageBox src={iconSrc} />
      <ImageBox src={txtImgSrc} />
      <ImageBox src={txtBoxImgSrc} />
    </Grid>
  );
};
